<template>
  <div ref="overview" v-lazyChart="{ fn: getRatios }" class="container">
    <div class="title">考情概述</div>
    <div class="sub-title">学科竞争力</div>
    <div class="operate">
      <div class="dataSource">
        <span v-if="type == 2">
          数据源：<a-radio-group
            v-model="dataSource"
            :options="plainOptions"
            @change="dataSourceChange"
          />
        </span>
      </div>
      <a-radio-group v-model="type" button-style="solid" @change="typeChange">
        <a-radio-button :value="1"> 均分分析 </a-radio-button>
        <a-radio-button :value="2"> 比率 </a-radio-button>
      </a-radio-group>
    </div>
    <a-table
      :data-source="tableData"
      bordered
      :row-key="(record, index) => index"
      :columns="columns"
      :scroll="{ x: 'max-content' }"
      :pagination="false"
      style="margin-bottom: 18px"
    ></a-table>
    <div v-if="type == 1" class="dataSource" style="margin-bottom: 18px">
      <span>
        数据源：<a-radio-group
          v-model="dataSource"
          :options="plainOptions"
          @change="dataSourceChange"
        />
      </span>
    </div>
    <div v-if="type === 2" class="ratioFilter">
      <a-select v-model="ratio" style="width: 220px" @change="setRatioSingle">
        <a-select-option
          v-for="(item, index) in ratioOptions"
          :key="index"
          :value="item.name"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
    </div>
    <chart :key="chartKey" :define-options="defineOptions" :series="series" />
  </div>
</template>
<script>
import chart from "../../../components/charts";
import { getstatratiooption } from "@/core/api/academic/common";
import { subjectcompetitive } from "@/core/api/newExamAcademic/union/school";
export default {
  name: "Overview",
  components: {
    chart,
  },
  data() {
    return {
      tableData: [],
      columns: [],
      dataSource: 0,
      type: 1,
      plainOptions: [
        {
          label: "原始分",
          value: 0,
        },
        {
          label: "赋分",
          value: 1,
        },
      ],
      avgCols: [
        {
          title: "学科",
          align: "center",
          dataIndex: "subjectName",
          customRender: (value, row, index) => {
            if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore) {
              const obj = {
                children: value,
                attrs: {},
              };
              if (index % 2 == 0) {
                obj.attrs.rowSpan = 2;
              } else {
                obj.attrs.rowSpan = 0;
              }
              return obj;
            } else {
              return value;
            }
          },
        },
        {
          title: "学校",
          align: "center",
          dataIndex: "schoolName",
        },
        {
          title: "统计人数",
          align: "center",
          dataIndex: "statTotal",
        },
        {
          title: "难度",
          align: "center",
          dataIndex: "scoreRateOri",
          customRender: (text, record) => {
            if (record.scoreRateOri) {
              return (record.scoreRateOri / 100).toFixed(2);
            } else {
              return "";
            }
          },
        },
        {
          title: "平均分",
          align: "center",
          dataIndex: "averageScoreOri",
        },
        {
          title: "赋分均分",
          align: "center",
          dataIndex: "averageScore",
          customRender: (text, reocrd) => {
            if ([1, 2, 3, 4, 8].includes(reocrd.subjectId)) {
              return "-";
            } else {
              return reocrd.averageScore;
            }
          },
        },
        {
          title: "中位数",
          align: "center",
          dataIndex: "medianOri",
        },
        {
          title: "最高分",
          align: "center",
          dataIndex: "highestScoreOri",
        },
        {
          title: "最低分",
          align: "center",
          dataIndex: "lowestScoreOri",
        },
        {
          title: "排名前27%均分",
          align: "center",
          dataIndex: "top27AverageScoreOri",
          customRender: (text, record) =>
            record.schoolName == "整体" ? "-" : record.top27AverageScoreOri,
        },
        {
          title: "排名后27%均分",
          align: "center",
          dataIndex: "after27AverageScoreOri",
          customRender: (text, record) =>
            record.schoolName == "整体" ? "-" : record.after27AverageScoreOri,
        },
        {
          title: "区分度",
          align: "center",
          dataIndex: "discriminationOri",
          customRender: (text, record) => {
            if (record.schoolName == "整体") {
              return "-";
            }
            return record.discriminationOri
              ? record.discriminationOri.toFixed(2)
              : "";
          },
        },
      ],
      ratioCols: [
        {
          title: "学科",
          align: "center",
          dataIndex: "subjectName",
          customRender: (value, row, index) => {
            if (
              this.$parent.topData.isSchoolDisplayReportUnionAverageScore != 1
            ) {
              return value;
            }
            const obj = {
              children: value,
              attrs: {},
            };
            if (index % 2 == 0) {
              obj.attrs.rowSpan = 2;
            } else {
              obj.attrs.rowSpan = 0;
            }
            return obj;
          },
        },
        {
          title: "学校",
          align: "center",
          dataIndex: "schoolName",
        },
        {
          title: "统计人数",
          align: "center",
          dataIndex: "statTotal",
        },
      ],
      ratio: null,
      ratioOptions: [],
      defineOptions: null,
      series: [],

      chartKey: Math.random(),
    };
  },
  created() {
    // this.typeChange();
    // this.getRatios();
  },
  methods: {
    async getData(loadingInstance, io) {
      const res = await subjectcompetitive({
        id: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.tableData = res.data.data.flatMap((item) =>
        item.schools.map((it) => ({
          ...it,
          subjectName: item.subjectName,
          subjectId: item.subjectId,
        }))
      );
      this.typeChange();
      this.closeLoading(loadingInstance, io);
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.overview);
        });
      }
    },
    async getRatios(loadingInstance, io) {
      const res = await getstatratiooption({ id: this.$route.query.id });
      this.ratioOptions = res.data.data;
      if (res.data.data.length) {
        this.ratio = res.data.data[0].name;
      }
      this.getData(loadingInstance, io);
    },
    createData() {
      let totalData = this.tableData.filter(
        (item) => item.schoolName == "整体"
      );
      let currentData = this.tableData.filter(
        (item) => item.schoolName == "本校"
      );
      if (this.dataSource == 1) {
        totalData = totalData.filter(
          (item) => ![1, 2, 3, 4, 8].includes(item.subjectId)
        );
        currentData = currentData.filter(
          (item) => ![1, 2, 3, 4, 8].includes(item.subjectId)
        );
      }
      if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore != 1) {
        totalData = [];
      }
      return { totalData: totalData, currentData: currentData };
    },
    dataSourceChange() {
      this.typeChange();
    },
    typeChange() {
      this.series = [];
      this.defineOptions = null;
      if (this.type == 1) {
        this.columns = [...this.avgCols];
        let { totalData, currentData } = this.createData();
        let key = this.dataSource == 1 ? "averageScore" : "averageScoreOri";
        this.defineOptions = {
          xAxis: {
            type: "category",
            axisTick: {
              show: false,
            },
            data: currentData.map((item) => item.subjectName),
            color: ["#2474ED", "#FF8857"],
          },
        };
        this.series = [
          {
            name: "学校",
            type: "bar",
            color: "#2474ED",
            label: {
              show: true,
              position: "top",
              color: "inherit",
            },
            barMaxWidth: 40,
            data: [...currentData.map((item) => item[key])],
          },
        ];
        if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
          this.series.push({
            name: "整体",
            type: "line",
            smooth: true,
            color: "#FF8857",
            data: [...totalData.map((item) => item[key])],
          });
        }
        this.chartKey = Math.random();
      } else if (this.type == 2) {
        let ratios = [];
        if (this.tableData.length) {
          ratios = this.tableData[0].ratios;
        }
        this.columns = [
          ...this.ratioCols,
          ...ratios.map((item, index) => ({
            title: item.name,
            dataIndex: "ratios" + index,
            align: "center",
            customRender: (text, record) => {
              let itemData = record.ratios[index];
              if (this.dataSource == 0) {
                return (
                  <span>
                    {itemData.totalOri}/{itemData.proportionOri}%
                  </span>
                );
              } else if (this.dataSource == 1) {
                return (
                  <span>
                    {itemData.total}/{itemData.proportion}%
                  </span>
                );
              }
            },
          })),
        ];
        this.setRatioSingle();
      }
    },
    setRatioSingle() {
      let { totalData, currentData } = this.createData();
      this.defineOptions = {
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          data: currentData.map((item) => item.subjectName),
          color: ["#2474ED", "#FF8857"],
        },
      };
      let key = this.dataSource == 1 ? "proportion" : "proportionOri";
      this.series = [
        {
          name: "学校",
          type: "bar",
          color: "#2474ED",
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
          barMaxWidth: 40,
          data: [
            ...currentData.map((item) => {
              const obj = item.ratios.find((it) => it.name === this.ratio);
              return obj[key];
            }),
          ],
        },
      ];
      if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
        this.series.push({
          name: "整体",
          type: "line",
          smooth: true,
          color: "#FF8857",
          data: [
            ...totalData.map((item) => {
              const obj = item.ratios.find((it) => it.name === this.ratio);
              return obj[key];
            }),
          ],
        });
      }
      this.chartKey = Math.random();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../new-exam.module.scss";

.operate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}

.ratioFilter {
  text-align: right;
  margin-bottom: 18px;
}
</style>
