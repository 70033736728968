<template>
  <div ref="student" v-lazyChart="{ fn: getCondition }" class="container">
    <exportBtn
      :request-func="requestFunc"
      :params="{
        id: $route.query.id,
        type: 5,
        ...wave,
        topOrBackward: topOrBackward,
      }"
    >
      <div class="title">重点关注学生</div>
    </exportBtn>
    <div class="operate">
      <a-radio-group v-model="type" button-style="solid" @change="typeChange">
        <a-radio-button v-if="showStudentRank()" :value="1">
          波动生
        </a-radio-button>
        <a-radio-button v-if="showStudentRank()" :value="2">
          拔尖生
        </a-radio-button>
        <a-radio-button v-if="showStudentRank()" :value="3">
          后进生
        </a-radio-button>
        <a-radio-button :value="4"> 跟踪生 </a-radio-button>
      </a-radio-group>
    </div>

    <template v-if="tableData.length">
      <div class="charts">
        <div v-if="type === 1" class="filter">
          <div>
            <span>年级排名前：</span>
            <a-input
              v-model="wave.upTotal"
              oninput="value=value.replace(/[^\d]/g,'')"
              :max-length="5"
              style="width: 64px"
              @blur="wave.upTotal = $event.target.value"
            ></a-input>
            <span>名中，与选中考试对比进步最大的前</span>
            <a-input
              v-model="wave.upMax"
              oninput="value=value.replace(/[^\d]/g,'')"
              style="width: 64px"
              :max-length="5"
              @blur="wave.upMax = $event.target.value"
            ></a-input>
            <span>名。</span>
          </div>
          <div>
            <a-select
              v-model="wave.upCompareId"
              not-found-content="暂无数据"
              style="width: 400px"
              @change="validateWave"
            >
              <a-select-option
                v-for="(item, index) in selectOptions"
                :key="'selectOptions' + index"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
            <a-button type="primary" @click="validateWave">确定</a-button>
          </div>
        </div>
        <div v-else-if="type === 2" class="filter">
          校排名前：<a-input
            v-model="topOrBackward"
            oninput="value=value.replace(/[^\d]/g,'')"
            :max-length="5"
            style="width: 72px; margin-right: 8px"
            @blur="topOrBackward = $event.target.value"
          ></a-input>
          <a-button type="primary" @click="getTopOrBackward">确定</a-button>
        </div>
        <div v-else-if="type === 3" class="filter">
          校排名后：<a-input
            v-model="topOrBackward"
            oninput="value=value.replace(/[^\d]/g,'')"
            :max-length="5"
            style="width: 72px; margin-right: 8px"
            @blur="topOrBackward = $event.target.value"
          ></a-input>
          <a-button type="primary" @click="getTopOrBackward">确定</a-button>
        </div>
        <chart
          :key="chartKey"
          :series="series1"
          :define-options="defineOptions"
        />
      </div>
      <template v-if="type === 1">
        <div class="charts">
          <div class="filter">
            <div>
              <span>年级排名前：</span>
              <a-input
                v-model="wave.downTotal"
                oninput="value=value.replace(/[^\d]/g,'')"
                style="width: 64px"
                @blur="wave.downTotal = $event.target.value"
              ></a-input>
              <span>名中，与选中考试对比退步最大的前</span>
              <a-input
                v-model="wave.downMax"
                oninput="value=value.replace(/[^\d]/g,'')"
                style="width: 64px"
                @blur="wave.downMax = $event.target.value"
              ></a-input>
              <span>名。</span>
            </div>
            <div>
              <a-select
                v-model="wave.downCompareId"
                not-found-content="暂无数据"
                style="width: 400px"
                @change="validateWave"
              >
                <a-select-option
                  v-for="(item, index) in selectOptions"
                  :key="'selectOptions' + index"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <a-button type="primary" @click="validateWave">确定</a-button>
            </div>
          </div>
          <chart
            :key="chartKey"
            :series="series2"
            :define-options="defineOptions"
          />
        </div>
      </template>
    </template>
    <no-data v-else />
    <div v-if="type != 4" class="classNum">
      选择班级：
      <classNums
        :key="type"
        ref="classNums"
        placeholder="选择班级"
        :class-num-list="classNumList"
        @change="classNumChange"
      />
    </div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      bordered
      :pagination="false"
      :row-key="(record, index) => index"
      style="margin-bottom: 18px"
      :scroll="{ x: 'max-content' }"
    >
      <div slot="upStudentList" slot-scope="text, record">
        <a-button
          v-for="(item, index) in record.StudentList"
          :key="index"
          type="link"
          >{{ item.name }}
        </a-button>
      </div>
    </a-table>

    <div class="tip" style="margin-top: 18px">
      <div v-if="type === 1">
        <div>
          波动生：以某次考试为对比入口，检索出波动幅度较大的学生，分为上升波动（处于成绩提升趋势）及下降波动（处于成绩下降趋势）。对上升波动的学生给予肯定，激励其保持上升趋势；对下降波动学生，辅助分析原因并给予调整建议和鼓励。特别是排名靠前或临界状态的学生，可进一步查看学生报告了解历次发展情况，进行波动性深度分析。
        </div>
        <div>注：不参与统计的学生不参与此处计算，也不在此处显示</div>
      </div>
      <div v-if="type === 2">
        <div>
          拔尖生（优生劣科）：了解拔尖生群体分布情况，以及优生劣科情况（拔尖生存在“严重偏科”的学科）。优生劣科可结合学生自身的学习特点分析，是否在本学科存在学有余力但方法不当或兴趣不高的情况，如存在应适当引导学生以总分为前提进行偏科学科的学习方法或学习状态的调整，同时也可提升班级总分高分群体占比。
        </div>
        <div>注：1.标橙学生为优生劣科学生</div>
        <div>2.不参与统计的学生不参与此处计算，也不在此处显示</div>
      </div>
      <div v-if="type === 3">
        <div>
          后进生（潜力学科）：了解后进生群体分布情况，以及潜力学科情况（后进生存在单科排名大幅度优于总分排名的学科）。后进生的潜力学科，可依据学生情况针对性分析，是否存在学有余力但学习动力不足的问题导致仅兴趣或天赋学科成绩较好，其他学科成绩滞后的情况。如存在应采取相应手段激发学生学习动力，并给予潜力学科肯定，如给予学生该兴趣学科的生涯规划指导等。
        </div>
        <div>注：1.标蓝学生为潜力生</div>
        <div>2.不参与统计的学生不参与此处计算，也不在此处显示</div>
      </div>
      <div v-if="type === 4">
        <div>
          跟踪生：仅当在报告设置中导入了跟踪生且跟踪生对应成功才显示。跟踪生（目标跟踪生）即为实现上线目标最稳定的学生群体，为目标基础保底儿。可从两方面设置
        </div>
        <div>
          一、（基于目标）结合学生入口成绩及（中）高考预期，确定学校跟踪生名单，各班数量不均。
        </div>
        <div>
          二、（基于管理）每班确定N名跟踪生，多为成绩稳定的尖子生，每班数量均匀。
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import chart from "../../../components/charts.vue";
import {
  waveStudent,
  topOrBackward,
  trackStudent,
} from "@/core/api/newExamAcademic/school";
import {
  unionWaveStudent,
  unionTopOrBackward,
  unionTrackStudent,
} from "@/core/api/newExamAcademic/union/school";
import classNums from "../../../components/classNums.vue";
import {
  getstatclassoption,
  getstatcomparelist,
} from "@/core/api/academic/common";
import { getSubjectList } from "@/core/util/util";
import exportBtn from "@/views/academic/components/exportBtn.vue";
import { exportSchoolNewReport } from "@/core/api/academic/schoolReportDetail";

export default {
  components: {
    chart,
    classNums,
    exportBtn,
  },
  data() {
    let subjectList = getSubjectList();
    return {
      type: 1,
      requestFunc: exportSchoolNewReport,
      wave: {
        downMax: 20,
        downTotal: 100,
        upMax: 20,
        upTotal: 100,
        upCompareId: null,
        downCompareId: null,
      },
      selectOptions: [],
      series1: [],
      series2: [],
      defineOptions: {},
      tableData: [],
      columns: [],
      classNum: null,
      classNumList: [],
      topOrBackward: 20,
      checkClassNum: [],
      chartKey: Math.random(),
      waveCol: [
        // 波动生
        {
          dataIndex: "classNum",
          title: "班级",
          align: "center",
          // width: 75,
        },
        {
          dataIndex: "teacherName",
          title: "班主任",
          align: "center",
          // width: 85,
        },
        {
          dataIndex: "statTotal",
          title: "统计考生",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "upTotal",
          title: "上升人数",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "upProportion",
          title: "占比",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "upStudents",
          title: "学生名单",
          align: "center",
          width: 250,
          customRender: (text, record) => {
            if (record.upStudents) {
              let data =
                record.extend || record.upStudents.length <= 9
                  ? record.upStudents
                  : record.upStudents.slice(0, 9);

              let button;
              if (record.upStudents.length > 9) {
                button = record.extend ? (
                  <a-button
                    type="link"
                    vOn:click={() => this.$set(record, "extend", false)}
                  >
                    收起
                  </a-button>
                ) : (
                  <a-button
                    type="link"
                    vOn:click={() => this.$set(record, "extend", true)}
                  >
                    查看更多
                  </a-button>
                );
              }
              return (
                <div class="nameList">
                  {...data.map((item) => (
                    <a-button
                      type="link"
                      style={{ color: item.type == 1 ? "#FF7A05" : "#2474ed" }}
                      vOn:click={() => this.handleClick(record, item)}
                    >
                      {item.name}
                    </a-button>
                  ))}
                  {button}
                </div>
              );
            }
          },
        },
        {
          dataIndex: "downTotal",
          title: "下降人数",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "downProportion",
          title: "占比",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "downStudents",
          title: "学生名单",
          align: "center",
          customRender: (text, record) => {
            if (record.downStudents) {
              let data =
                record.extend || record.downStudents.length <= 9
                  ? record.downStudents
                  : record.downStudents.slice(0, 9);

              let button;
              if (record.downStudents.length > 9) {
                button = record.extend ? (
                  <a-button
                    type="link"
                    vOn:click={() => this.$set(record, "extend", false)}
                  >
                    收起
                  </a-button>
                ) : (
                  <a-button
                    type="link"
                    vOn:click={() => this.$set(record, "extend", true)}
                  >
                    查看更多
                  </a-button>
                );
              }
              return (
                <div class="nameList">
                  {...data.map((item) => (
                    <a-button
                      type="link"
                      vOn:click={() => this.handleClick(record, item)}
                    >
                      {item.name}
                    </a-button>
                  ))}
                  {button}
                </div>
              );
            }
          },
        },
      ],
      topCol: [
        {
          dataIndex: "classNum",
          title: "班级",
          align: "center",
          width: 75,
        },
        {
          dataIndex: "teacherName",
          title: "班主任",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "statTotal",
          title: "统计人数",
          align: "center",
          width: 100,
        },
        {
          title: "拔尖生",
          align: "center",
          children: [
            {
              dataIndex: "total",
              title: "人数",
              align: "center",
              width: 75,
              customRender: (text, record) => record.total,
            },
            {
              dataIndex: "proportion",
              title: "占比",
              align: "center",
              width: 100,
              customRender: (text, record) => (record.proportion || 0) + "%",
            },
            {
              dataIndex: "specialTotal",
              title: "优生劣科人数",
              align: "center",
              width: 150,
              customRender: (text, record) => record.specialTotal,
            },
            {
              dataIndex: "specialProportion",
              title: "优生劣科占比",
              align: "center",
              customRender: (text, record) =>
                (record.specialProportion || 0) + "%",
              width: 150,
            },
            {
              title: "学生名单",
              align: "center",
              width: 250,
              customRender: (text, record) => {
                if (record.students) {
                  let data =
                    record.extend || record.students.length <= 9
                      ? record.students
                      : record.students.slice(0, 9);

                  let button;
                  if (record.students.length > 9) {
                    button = record.extend ? (
                      <a-button
                        type="link"
                        vOn:click={() => this.$set(record, "extend", false)}
                      >
                        收起
                      </a-button>
                    ) : (
                      <a-button
                        type="link"
                        vOn:click={() => this.$set(record, "extend", true)}
                      >
                        查看更多
                      </a-button>
                    );
                  }
                  return (
                    <div class="nameList">
                      {...data.map((item) => (
                        <a-button
                          type="link"
                          style={{
                            color: item.type == 1 ? "#FF7A05" : "#40a9ff",
                          }}
                          vOn:click={() => this.handleClick(record, item)}
                        >
                          {item.name}
                        </a-button>
                      ))}
                      {button}
                    </div>
                  );
                }
              },
            },
          ],
        },
      ],
      backCol: [
        {
          dataIndex: "classNum",
          title: "班级",
          align: "center",
          width: 75,
        },
        {
          dataIndex: "teacherName",
          title: "班主任",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "statTotal",
          title: "统计人数",
          align: "center",
          width: 100,
        },
        {
          title: "后进生",
          align: "center",
          children: [
            {
              dataIndex: "total",
              title: "人数",
              width: 75,
              align: "center",
              customRender: (text, record) => record.total,
            },
            {
              dataIndex: "proportion",
              width: 100,
              title: "占比",
              align: "center",
              customRender: (text, record) => record.proportion + "%",
            },
            {
              dataIndex: "specialTotal",
              title: "潜在科目人数",
              width: 150,
              align: "center",
              customRender: (text, record) => record.specialTotal,
            },
            {
              dataIndex: "specialProportion",
              title: "潜在科目占比",
              width: 150,
              align: "center",
              customRender: (text, record) => record.specialProportion + "%",
            },
            {
              title: "学生名单",
              align: "center",
              width: 250,
              customRender: (text, record) => {
                if (record.students) {
                  let data =
                    record.extend || record.students.length <= 9
                      ? record.students
                      : record.students.slice(0, 9);

                  let button;
                  if (record.students.length > 9) {
                    button = record.extend ? (
                      <a-button
                        type="link"
                        vOn:click={() => this.$set(record, "extend", false)}
                      >
                        收起
                      </a-button>
                    ) : (
                      <a-button
                        type="link"
                        vOn:click={() => this.$set(record, "extend", true)}
                      >
                        查看更多
                      </a-button>
                    );
                  }
                  return (
                    <div class="nameList">
                      {...data.map((item) => (
                        <a-button
                          type="link"
                          vOn:click={() => this.handleClick(record, item)}
                        >
                          {item.name}
                        </a-button>
                      ))}
                      {button}
                    </div>
                  );
                }
              },
            },
          ],
        },
      ],
      trackCol: [
        {
          dataIndex: "classNum",
          title: "班级",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "name",
          title: "姓名",
          align: "center",
          width: 85,
        },
      ],
      subjectList: [...subjectList],
    };
  },
  mounted() {
    // this.typeChange();
  },
  methods: {
    showStudentRank() {
      if (this.$route.query.examType == 1) {
        return this.$parent.topData.isSchoolDisplayReportStudentRank == 1;
      }
      return true;
    },
    handleClick(record, item) {
      const { href } = this.$router.resolve({
        path: "/academic/new-exam/student-report",
        query: {
          ...this.$route.query,
          studentName: item.name,
          studentId: item.studentId,
          subjectId: 0,
          classNum: record.classNum,
          level: this.$route.query.level,
        },
      });
      let timer = setTimeout(() => {
        window.open(href, "_blank");
        clearTimeout(timer);
      }, 0);
    },
    async getCondition(loadingInstance, io) {
      const classRes = await getstatclassoption({
        id: this.$route.query.id,
        requestPage: 1,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.classNumList = classRes.data.data.map((item) => item.name);
      this.checkClassNum = classRes.data.data.map((item) => item.name);
      if (!this.showStudentRank()) this.type = 4;
      this.typeChange();
      this.closeLoading(loadingInstance, io);
    },
    async getWaveData() {
      let res;
      if (this.$route.query.schoolId) {
        res = await unionWaveStudent({
          classNums: this.checkClassNum.join(","),
          ...this.wave,
          id: this.$route.query.id,
          isAllClass: 0,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await waveStudent({
          classNums: this.checkClassNum.join("1"),
          ...this.wave,
          id: this.$route.query.id,
          isAllClass: 0,
        });
      }

      this.tableData = res.data.data;
      this.defineOptions = {
        xAxis: {
          axisTick: {
            show: false,
          },
          type: "category",
          data: [...this.tableData.map((item) => item.classNum)],
        },
      };
      this.series1 = [
        {
          type: "bar",
          name: "波动生",
          barMaxWidth: 40,

          data: [...this.tableData.map((item) => item.upTotal)],
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
        },
      ];
      this.series2 = [
        {
          type: "bar",
          name: "波动生",
          data: [...this.tableData.map((item) => item.downTotal)],
          barMaxWidth: 40,

          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
        },
      ];
      this.chartKey = Math.random();
    },
    async getTopOrBackward() {
      let res;
      if (this.$route.query.schoolId) {
        res = await unionTopOrBackward({
          topOrBackward: this.topOrBackward,
          type: this.type == 2 ? 1 : 2,
          classNums: this.checkClassNum.toString(),
          isAllClass: 0,
          id: Number(this.$route.query.id),
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await topOrBackward({
          topOrBackward: this.topOrBackward,
          type: this.type == 2 ? 1 : 2,
          classNums: this.checkClassNum.toString(),
          isAllClass: 0,
          id: this.$route.query.id,
        });
      }

      this.tableData = res.data.data;
      this.defineOptions = {
        xAxis: {
          axisTick: {
            show: false,
          },
          type: "category",
          data: [...this.tableData.map((item) => item.classNum)],
        },
      };
      this.series1 = [
        {
          type: "bar",
          name: this.type === 2 ? "拔尖生" : "后进生",
          data: [...this.tableData.map((item) => item.total)],
          barMaxWidth: 40,
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
        },
      ];
      this.chartKey = Math.random();
    },
    async trackStudent() {
      let res;
      if (this.$route.query.schoolId) {
        res = await unionTrackStudent({
          id: this.$route.query.id,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await trackStudent({ id: this.$route.query.id });
      }

      if (res.data.data.students) {
        let students = res.data.data.students;
        let subjects = [];
        if (students.length) {
          subjects = students[0].subjects;
        }
        let subjectCol = subjects.flatMap((item, index) => {
          return [
            {
              dataIndex: "subject" + index,
              title: "科目",
              align: "center",
              width: 85,
              customRender: (text, record) => {
                if (record.subjects[index].subjectId === 0) {
                  return "总分";
                } else {
                  let subject = this.subjectList.find(
                    (it) => it.value == record.subjects[index].subjectId
                  );
                  return subject.label;
                }
              },
            },
            {
              dataIndex: "rank" + index,
              title: "校排名",
              width: 85,
              align: "center",
              customRender: (text, record) => {
                return record.subjects[index].rank;
              },
            },
            {
              dataIndex: "teacherName" + index,
              title: item.subjectId === 0 ? "班主任" : "任课老师",
              width: 120,
              align: "center",
              customRender: (text, record) => {
                return record.subjects[index].teacherName;
              },
            },
          ];
        });
        this.columns = [
          ...this.trackCol,
          {
            dataIndex: "littleSubject",
            title: "学科薄弱程度",
            align: "center",
            width: 150,
            customRender: (text, record) => {
              return (
                <div>
                  {record.littleSubject && record.littleSubject.length ? (
                    <div>
                      <span style="color:#f56c6c">
                        {record.littleSubject.toString()}
                      </span>
                      稍薄弱学科
                    </div>
                  ) : (
                    ""
                  )}
                  {record.seriousSubject && record.seriousSubject.length ? (
                    <div>
                      <span style="color:#f56c6c">
                        {record.seriousSubject.toString()}
                      </span>
                      严重偏科学科
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            },
          },
          ...subjectCol,
        ];
        this.tableData = res.data.data.students;
        this.defineOptions = {
          xAxis: {
            axisTick: {
              show: false,
            },
            type: "category",
            data: [...res.data.data.classes.map((item) => item.classNum)],
          },
        };
        this.series1 = [
          {
            type: "bar",
            name: "跟踪生",
            data: [...res.data.data.classes.map((item) => item.total)],
            barMaxWidth: 40,

            label: {
              show: true,
              position: "top",
              color: "inherit",
            },
          },
        ];
        this.chartKey = Math.random();
      }
    },
    classNumChange(val) {
      this.checkClassNum = val;
      this.typeChange();
    },
    async getstatcomparelistData() {
      const res = await getstatcomparelist({
        statId: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.selectOptions = res.data.data;
      if (res.data.data.length) {
        this.wave.upCompareId = res.data.data[0].id;
        this.wave.downCompareId = res.data.data[0].id;
        this.getWaveData();
      }
    },
    typeChange() {
      this.series1 = [];
      this.series2 = [];
      this.defineOptions = null;
      if (this.type === 1) {
        this.columns = [...this.waveCol];
        this.getstatcomparelistData();
      } else if (this.type === 2) {
        this.columns = [...this.topCol];
        this.topOrBackward = !this.topOrBackward ? 20 : this.topOrBackward;
        this.getTopOrBackward();
      } else if (this.type === 3) {
        this.columns = [...this.backCol];
        this.topOrBackward = !this.topOrBackward ? 20 : this.topOrBackward;

        this.getTopOrBackward();
      } else if (this.type === 4) {
        this.trackStudent();
      }
    },
    validateWave() {
      if (
        !/(^[1-9]\d*$)/.test(this.wave.upTotal) ||
        !/(^[1-9]\d*$)/.test(this.wave.upMax)
      ) {
        this.$message("请输入正整数");
        return;
      } else if (!this.wave.upCompareId) {
        this.$message({
          showClose: false,
          message: "请选择对比考试",
          type: "error",
        });
      } else {
        this.getWaveData();
      }
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.student);
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../new-exam.module.scss";

.operate {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 18px;
}

.charts {
  margin-bottom: 18px;

  .filter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 18px;

    span {
      margin: 0 8px;
    }

    div {
      margin-right: 18px;
    }
  }
}

.classNum {
  margin-bottom: 18px;
}

.subjectShit {
  color: #f56c6c;
}

.nameList {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
</style>
