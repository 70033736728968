export const avg = [
  {
    title: "学科",
    align: "center",
    dataIndex: "subjectName",
  },
  {
    title: "统计人数",
    align: "center",
    dataIndex: "statTotal",
    customRender: (text, record) => (
      <div>
        <div>{record.statTotal}</div>
        <div>2</div>
      </div>
    ),
  },
  {
    title: "难度",
    align: "center",
    dataIndex: "scoreRateOri",
    customRender: (text, record) => {
      return record.scoreRateOri.toFixed(2);
    },
  },
  {
    title: "平均分",
    align: "center",
    dataIndex: "averageScoreOri",
  },
  {
    title: "中位数",
    align: "center",
    dataIndex: "medianOri",
  },
  {
    title: "赋分均分",
    align: "center",
    dataIndex: "averageScore",
    customRender: (text, reocrd) => {
      if ([1, 2, 3, 4, 8].includes(reocrd.subjectId)) {
        return "-";
      } else {
        return reocrd.averageScore;
      }
    },
  },
  {
    title: "排名前27%均分",
    align: "center",
    dataIndex: "top27AverageScoreOri",
  },
  {
    title: "排名后27%均分",
    align: "center",
    dataIndex: "after27AverageScoreOri",
  },
  {
    title: "最高分",
    align: "center",
    dataIndex: "highestScoreOri",
  },
  {
    title: "最低分",
    align: "center",
    dataIndex: "lowestScoreOri",
  },
  {
    title: "标准差",
    align: "center",
    dataIndex: "standardDeviationOri",
  },
  {
    title: "区分度",
    align: "center",
    dataIndex: "discriminationOri",
    customRender: (text, record) => record.discriminationOri.toFixed(2),
  },
];
export const ratio = [
  {
    title: "学科",
    align: "center",
    dataIndex: "subjectName",
  },
  {
    title: "统计人数",
    align: "center",
    dataIndex: "statTotal",
  },
];

export const topSetction = [
  {
    title: "学科",
    align: "center",
    dataIndex: "subjectName",
  },
  {
    title: "统计人数",
    align: "center",
    dataIndex: "statTotal",
  },
];
