<template>
  <div ref="development" v-lazyChart="{ fn: getCompareList }" class="container">
    <div class="title">发展性分析</div>
    <div class="tabs">
      <a-tabs v-model="type" @change="tabChange">
        <a-tab-pane key="2" tab="按组合查看"> </a-tab-pane>
        <a-tab-pane key="1" tab="按科目查看"> </a-tab-pane>
      </a-tabs>
    </div>
    <examTransformVue
      :stat-id="$route.query.id"
      :exam-arr="examArr"
      :check-list="checkList"
      new-exam
      is-ap
      style="margin-bottom: 18px"
      @submit="getExamId"
    />

    <div v-if="type === '1'" class="btns">
      <div
        v-for="(item, index) in subjectList"
        :key="index"
        :class="['btns-item', subjectId === item.id ? 'btns-active' : '']"
        @click="subjectClick(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div v-if="type === '2'" class="btns">
      <a-select
        v-model="category"
        not-found-content="暂无数据"
        style="margin-bottom: 18px; margin-right: 8px; width: 180px"
        @change="categoryChange"
      >
        <a-select-option v-if="$parent.topData.hasPhysics == 1" :value="1">
          物理类
        </a-select-option>
        <a-select-option v-if="$parent.topData.hasHistory == 1" :value="2">
          历史类
        </a-select-option>
      </a-select>
      <div
        v-for="(item, index) in electives"
        :key="index"
        :class="['btns-item', electiveId === item.id ? 'btns-active' : '']"
        @click="electiveClick(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="operate">
      <div>
        选择{{ getClassType() }}班级：
        <classNums
          :key="classNumKey"
          ref="classNums"
          placeholder="选择班级"
          :class-num-list="classNumList"
          @change="classNumChange"
        />
      </div>
      <a-radio-group
        v-model="dataType"
        button-style="solid"
        @change="dataTypeChange"
      >
        <a-radio-button value="1"> 超均率 </a-radio-button>
        <a-radio-button v-if="ratios.length" value="2"> 比率 </a-radio-button>
        <a-radio-button v-if="type == 1 && topSections.length" value="3">
          前N名
        </a-radio-button>
      </a-radio-group>
    </div>
    <div v-if="dataType !== '1'" class="params">
      <a-select
        :key="dataType"
        v-model="params"
        style="width: 200px"
        not-found-content="暂无数据"
        @change="paramsChange"
      >
        <a-select-option
          v-for="(item, index) in options"
          :key="index"
          :value="item.name"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
    </div>
    <chart
      v-if="defineOptions && series.length"
      :key="chartKey"
      :define-options="defineOptions"
      :series="series"
    />
    <no-data v-else />
    <div class="tip">
      <div class="intent">注：</div>
      <div>
        <div>折线图——班级历次考试指标值</div>
        <div>
          1.
          同色折线不同考试节点对比：最近一次考试与历次考试进行对比，用于查看班级考试指标的变化趋势。
        </div>
        <div>
          2.
          同一考试节点不同颜色值对比：每次考试各班级间指标的差距。可选择同比、环比及周期性对比的角度进行发展性的分析。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import examTransformVue from "../../../components/examTransform.vue";
import { examTypeOptions } from "@/core/util/constdata";
import chart from "../../../components/charts.vue";
import classNums from "../../../components/classNums.vue";
import {
  getstatsubjectoption,
  getstateleccomboptionV2,
  getstatclassoption,
  getstatdevelopanalysiscomparelist,
} from "@/core/api/academic/common";
import { getDevelopment } from "@/core/api/newExamAcademic/school";
import { unionGetschoolreportdevelopanalysis } from "@/core/api/newExamAcademic/union/school";
export default {
  name: "Development",
  components: {
    examTransformVue,
    chart,
    classNums,
  },
  data() {
    return {
      checkList: [],
      defineOptions: null,
      series: [],
      ratios: [],
      topSections: [],
      type: "2",
      examList: [],
      visible: false,
      confirmLoading: false,
      examTypeOptions: examTypeOptions,
      examTypes: [],
      category: null,
      subjectList: [],
      electiveId: null,
      electives: [],
      subjectId: 0,
      classNum: null,
      classNumList: [],
      classNumKey: Math.random(),
      compareList: [],
      examArr: [],
      dataType: "1",
      params: "",
      options: [],
      chartKey: Math.random(),
    };
  },
  mounted() {
    if (this.$parent.topData.hasPhysics == 1) {
      this.category = 1;
    } else if (this.$parent.topData.hasHistory == 1) {
      this.category = 2;
    }
  },
  methods: {
    classNumChange(val) {
      this.classNum = val;
      this.getData();
    },
    getExamId(val) {
      this.compareIds = val;
      this.getData();
    },
    async getCompareList(loadingInstance, io) {
      const res = await getstatdevelopanalysiscomparelist({
        statId: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
        examTypes:
          this.examTypes && this.examTypes.length
            ? this.examTypes.toString()
            : "",
      }).catch(() => this.closeLoading(loadingInstance, io));
      this.compareList = res.data.data;
      if (res.data.data.length > 5) {
        let data = res.data.data.slice(0, 5);
        this.compareIds = data.map((item) => item.id);
      } else {
        this.compareIds = res.data.data.map((item) => item.id);
      }
      this.tabChange();
      this.closeLoading(loadingInstance, io);
    },
    async getSubectList() {
      const res = await getstatsubjectoption({
        id: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
        requestPage: 1,
        hasTotal: 0,
      });
      this.subjectList = res.data.data;
      if (res.data.data.length) {
        this.subjectId = res.data.data[0].id;
        this.getClassList();
      }
    },
    async geteleccomboption() {
      let obj = {
        1: 98,
        2: 99,
      };
      const res = await getstateleccomboptionV2({
        id: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
        hasCate: 1,
        eleccateId: obj[this.category],
      });
      this.electives = res.data.data;

      if (this.electives.length) {
        this.electiveId = this.electives[0].id;
        this.getClassList();
      }
    },
    async getClassList() {
      const res = await getstatclassoption({
        id: this.$route.query.id,
        subjectId: this.type == 1 ? this.subjectId : null,
        viewSchoolId: this.$route.query.schoolId,
        requestPage: 1,
      });
      this.classNumList = res.data.data.map((item) => item.name);
      this.classNum = this.classNumList.toString();
      this.classNumKey = Math.random();
      this.getData();
    },
    categoryChange() {
      this.geteleccomboption();
    },

    async getCondition(loadingInstance, io) {
      const res = await getstatsubjectoption({
        id: this.$route.query.id,
        requestPage: 1,
        viewSchoolId: this.$route.query.schoolId,
        hasTotal: 1,
      }).catch(() => this.closeLoading(loadingInstance, io));
      this.electives = res.data.data;
      this.getClassList();
      this.closeLoading(loadingInstance, io);
    },

    closeLoading(loadingInstance, io) {
      if ((loadingInstance, io)) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.development);
        });
      }
    },
    async getData() {
      let res;
      if (this.$route.query.schoolId) {
        res = await unionGetschoolreportdevelopanalysis({
          id: this.$route.query.id,
          classNums: this.classNum.join(","),
          contrastStatId: this.compareIds.join(","),
          eleccombId: this.electiveId || null,
          subjectId: this.subjectId,
          viewType: this.type,
          isAllClass: 0,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getDevelopment({
          id: this.$route.query.id,
          classNums: this.classNum.join(","),
          contrastStatId: this.compareIds.join(","),
          eleccombId: this.electiveId || null,
          subjectId: this.subjectId,
          viewType: this.type,
          isAllClass: 0,
        });
      }

      if (res.data.data.length) {
        this.examArr = res.data.data;
        this.checkList = res.data.data;
        this.ratios = res.data.data[0].ratios;
        this.topSections = res.data.data[0].topSections;
      }
      this.dataTypeChange();
    },
    paramsChange() {
      const enmu = {
        2: "ratios",
        3: "topSections",
      };
      let key = enmu[this.dataType];
      this.setchart(key);
    },
    dataTypeChange() {
      this.defineOptions = null;
      this.options = [];
      this.series = [];
      if (this.examArr.length) {
        this.defineOptions = {
          legend: {
            itemGap: 20,
            type: "scroll",
            orient: "horizontal",
          },
          xAxis: {
            type: "category",
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              interval: 0,
              width: 100,
              overflow: "breakAll",
            },
            data: [...this.examArr.map((item) => item.name)],
          },
        };
        let classes = this.examArr[0].classes.filter(
          (item) => item.classNum != "我校"
        );
        const data = this.examArr.flatMap((item) => item.classes);
        if (this.dataType == "1") {
          this.series = classes.map((item) => {
            const sameClass = data.filter(
              (it) => it.classNum === item.classNum
            );
            return {
              name: item.classNum + "超均率（校）",
              type: "line",
              label: {
                show: true,
                textStyle: {
                  color: "inherit",
                  fontSize: 9,
                },
                position: "top",
              },
              data: sameClass.map((item) => item.overAverageRateOri || 0),
            };
          });
        } else if (this.dataType == "2") {
          if (classes.length) {
            if (classes[0].ratios && classes[0].ratios.length) {
              this.options = classes[0].ratios;
              this.params = this.options[0].name;
              this.setchart("ratios");
            }
          }
        } else if (this.dataType == "3") {
          if (classes.length) {
            if (classes[0].topSections && classes[0].topSections.length) {
              this.options = classes[0].topSections;
              this.params = this.options[0].name;
              this.setchart("topSections");
            }
          }
        }
      }
      this.chartKey = Math.random();
    },
    setchart(field) {
      this.series = [];
      let classes = this.examArr[0].classes;
      const data = this.examArr.flatMap((item) => item.classes);
      this.series = classes.map((item) => {
        const a = data.filter((it) => item.classNum === it.classNum);
        const fin = a.map((i) => {
          const itemData = i[field].find((n) => n.name === this.params);
          if (itemData) {
            return itemData.proportion;
          } else {
            return 0;
          }
        });
        return {
          name: item.classNum,
          type: "line",
          label: {
            show: true,
            textStyle: {
              color: "inherit",
              fontSize: 9,
            },
            position: "top",
          },
          data: fin,
        };
      });
      this.chartKey = Math.random();
    },
    tabChange() {
      this.dataType = "1";
      if (this.type == 1) {
        this.getSubectList();
      } else if (this.type == 2) {
        this.categoryChange();
      }
    },
    getExamList() {},
    subjectClick(item) {
      this.subjectId = item.id;
      this.getClassList();
    },
    electiveClick(item) {
      this.electiveId = item.id;
      this.getData();
    },
    getClassType() {
      if (this.type == "2") {
        return "行政";
      } else {
        if (this.subjectId > 3 || this.electiveId !== null) {
          return "教学";
        }
        return "行政";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../new-exam.module.scss";

.tabs {
  //   margin-bottom: 18px;
}
.compare {
  background: #f3f8ff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  color: #3e4551;
  font-size: 14px;
  margin-bottom: 18px;
}
.btns {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .btns-item {
    border-radius: 4px;
    border: 1px solid #d5d6db;
    box-sizing: border-box;
    padding: 6px 18px;
    color: #0a1119;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 18px;
    transition: all 0.2s ease-in-out;
    &:hover {
      border-color: #2474ed;
      background-color: #2474ed;
      color: #ffff;
    }
  }
  .btns-active {
    border-color: #2474ed;
    background-color: #2474ed;
    color: #ffff;
  }
  .btns-item + .btns-item {
    margin-left: 8px;
  }
}
.exam-filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 18px;
  .filter-item + .filter-item {
    margin-left: 8px;
  }
}
::v-deep .ant-select-selection--single {
  height: 35px;
  line-height: 35px;
}
.operate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}
.params {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 18px;
}
</style>
