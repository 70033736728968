<template>
  <div class="stat-contain">
    <div class="topData">
      <div class="school-tag">{{ schoolName }}</div>
      <div class="title">
        <div class="new-exam-tag">新高考</div>
        <div class="text">{{ topData.name }}</div>
        <div :class="['sub-tag', 'general']">
          {{ getExamType(topData.type) }}
        </div>
      </div>
      <div class="sub">
        <div class="back" @click="back"><a-icon type="left" />返回</div>
        <div class="date">
          <span>年级：{{ topData.year }}级</span>
          <span>考试时间：{{ topData.examDate }}</span>
        </div>
        <div></div>
      </div>
      <exportBtn
        :request-func="requestFunc"
        :params="{ id: $route.query.id, type: 0 }"
        btn-type="primary"
        text="导出全部报表"
      >
        <div class="model-title">校级报告</div>
      </exportBtn>
      <div class="dashboard">
        <div
          :class="[
            'box',
            'normal',
            topData.hasPhysics != 1 || topData.hasHistory != 1
              ? 'flex-box'
              : '',
          ]"
        >
          <div class="item border-item">
            <div class="item-title">
              {{ topData.eleccombCount }}<span>个</span>
            </div>
            <div>选考组合</div>
          </div>
          <div class="item">
            <div class="item-title">
              {{ topData.classCount }}<span>个</span>
            </div>
            <div>行政班</div>
          </div>
          <div class="item">
            <div class="item-title">
              {{ topData.studentCount }}<span>名</span>
            </div>
            <div>考生</div>
          </div>
          <div class="item">
            <div class="item-title">{{ topData.statTotal }}<span>名</span></div>
            <div>统计人数</div>
          </div>
        </div>
        <div
          v-if="topData.hasPhysics == 1 || topData.hasHistory == 1"
          class="vertical-text"
        >
          最 高 分
        </div>
        <div
          v-if="topData.hasPhysics == 1 || topData.hasHistory == 1"
          :class="[
            'box',
            'other',
            topData.hasPhysics != 1 || topData.hasHistory != 1
              ? 'flex-box'
              : '',
          ]"
        >
          <template v-if="topData.hasPhysics == 1">
            <div
              :class="[
                'item',
                topData.historyMaxScoreOri && topData.historyMaxScore
                  ? 'border-item'
                  : '',
              ]"
            >
              <div class="flex">
                <div class="sub-item sub-border-item">
                  <div class="item-title">
                    {{ topData.physicsMaxScoreOri || "-" }}
                  </div>
                  <div>原始分</div>
                </div>
                <div class="sub-item">
                  <div class="item-title">
                    {{ topData.physicsMaxScore || "-" }}
                  </div>
                  <div>赋分</div>
                </div>
              </div>
              <div>物理类</div>
            </div>
          </template>
          <template v-if="topData.hasHistory == 1">
            <div class="item">
              <div class="flex">
                <div class="sub-item sub-border-item">
                  <div class="item-title">
                    {{ topData.historyMaxScoreOri || "-" }}
                  </div>
                  <div>原始分</div>
                </div>
                <div class="sub-item">
                  <div class="item-title">
                    {{ topData.historyMaxScore || "-" }}
                  </div>
                  <div>赋分</div>
                </div>
              </div>
              <div>历史类</div>
            </div>
          </template>
        </div>
        <div class="box success">
          <div
            v-if="topData.hasPhysics == 1"
            :class="[
              'item',
              topData.hasPhysics == 1 && topData.hasHistory == 1
                ? 'border-item'
                : '',
            ]"
          >
            <div class="item-title">
              {{ topData.physicsNo1Class }}
            </div>
            <div>物理类No.1</div>
          </div>
          <div v-if="topData.hasHistory == 1" class="item">
            <div class="item-title">
              {{ topData.historyNo1Class }}
            </div>
            <div>历史类No.1</div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="topData.name">
      <!-- 考情概述 start-->
      <unionOverview v-if="$route.query.schoolId" id="overview" />
      <overview v-else id="overview" />
      <!-- 考情概述 end-->
      <!-- 组合竞争力 start -->
      <unionCompetitiveness v-if="$route.query.schoolId" />
      <competitiveness v-else />
      <!-- 组合竞争力 end -->
      <!-- 成绩分布 start -->
      <branch />
      <!-- 成绩分布 end -->
      <template v-if="topData.statClass == 1">
        <!-- 班级竞争力 start -->
        <unionClassCompetitive
          v-if="$route.query.schoolId"
          id="competitiveness"
        />
        <class-competitive v-else id="competitiveness" />
        <!-- 班级竞争力 end -->
        <!-- 班级成绩分布 start -->
        <classBranch />
      </template>
      <!-- 班级成绩分布 end -->
      <!-- 班级学科均衡性对比 start -->
      <balance v-if="showStatClass()" id="balance" />
      <!-- 班级学科均衡性对比 end -->
      <!-- 重点关注学生 start -->
      <student id="student" />
      <!-- 重点关注学生 end -->
      <!-- 发展性分析 start -->
      <development id="development" />
      <!-- 发展性分析 end -->
    </template>
    <pageNav :menu-list="menuList" />
  </div>
</template>
<script>
import { examTypeOptions } from "@/core/util/constdata";
import overview from "./components/overview/index.vue";
import competitiveness from "./components/competitiveness/index.vue";
import branch from "./components/branch";
import classCompetitive from "./components/classCompetitiveness";
import classBranch from "./components/classBranch";
import balance from "./components/balance";
import student from "./components/student";
import development from "./components/development";
import unionOverview from "./union/overview/index.vue";
import unionCompetitiveness from "./union/competitiveness";
import unionClassCompetitive from "./union/classCompetitiveness";
import { getTopData } from "@/core/api/newExamAcademic/school";
import { getStore } from "@/core/util/store";
import pageNav from "@/views/academic/components/pageNav.vue";
import exportBtn from "@/views/academic/components/exportBtn.vue";
import { exportSchoolNewReport } from "@/core/api/academic/schoolReportDetail";
import { getschoolreporttopinfo } from "@/core/api/newExamAcademic/union/school";
export default {
  components: {
    overview,
    unionOverview,
    competitiveness,
    unionCompetitiveness,
    branch,
    classCompetitive,
    unionClassCompetitive,
    classBranch,
    balance,
    student,
    development,
    pageNav,
    exportBtn,
  },
  data() {
    return {
      topData: {
        type: 99,
      },
      requestFunc: exportSchoolNewReport,
      schoolName: null,
      menuList: [
        {
          id: "overview",
          name: "考情概述",
        },
        {
          id: "competitiveness",
          name: "班级竞争力",
        },
        {
          id: "balance",
          name: "均衡性对比",
        },
        {
          id: "student",
          name: "重点关注学生",
        },
        {
          id: "development",
          name: "发展性分析",
        },
      ],
    };
  },
  mounted() {
    this.getData();

    if (this.$route.query.examType == 1) {
      this.schoolName = this.$route.query.schoolName;
    } else {
      this.schoolName = getStore({ name: "userInfo" }).schoolName;
    }
  },

  methods: {
    showStatClass() {
      if (this.$route.query.examType == 1) {
        return this.topData.statClass == 1;
      }
      return true;
    },
    getExamType(type) {
      if (type) {
        const data = examTypeOptions.find((item) => item.value === type);
        return data.label;
      }
    },
    back() {
      this.$router.go(-1);
    },
    async getData() {
      let res;
      if (this.$route.query.schoolId) {
        res = await getschoolreporttopinfo({
          id: this.$route.query.id,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getTopData({
          id: this.$route.query.id,
        }).catch(() => {});
      }

      this.topData = res.data.data;
      if (this.topData.statClass != 1) {
        this.menuList = this.menuList.filter(
          (item) => !["competitiveness", "balance"].includes(item.id)
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../new-exam.module.scss";

.normal {
  background-color: #f3f8ff;

  .item-title {
    color: #2474ed;
    font-size: 18px;
    margin-bottom: 8px;
    line-height: 1;

    span {
      font-size: 12px;
    }
  }

  .border-item {
    border-right: 1px dashed #d2e2f9;
    padding-right: 24px;
    box-sizing: border-box;
  }
}

.other {
  background-color: #fff8f3;

  .item-title {
    color: #ff7a05;
    font-size: 18px;
    margin-bottom: 8px;
    line-height: 1;

    span {
      font-size: 12px;
    }
  }

  .border-item {
    border-right: 1px dashed #f3bd96;
    padding-right: 24px;
    box-sizing: border-box;
  }
}

.success {
  background-color: #f3fcf8;

  .item-title {
    color: #2bb472;
    font-size: 18px;
    margin-bottom: 8px;
    line-height: 1;

    span {
      font-size: 12px;
    }
  }

  .border-item {
    border-right: 1px dashed #c2e9d8;
    padding-right: 24px;
    box-sizing: border-box;
  }
}

.sub-item {
  font-size: 12px;
  margin-bottom: 8px;

  .item-title {
    margin-bottom: 8px !important;
  }
}

.sub-border-item {
  border-right: 1px dashed #fee2ce;
  padding-right: 24px;
  box-sizing: border-box;
}

.sub-item + .sub-item {
  margin-left: 24px;
}

.flex {
  display: flex;
  align-items: center;
}

.vertical-text {
  writing-mode: vertical-lr;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  background: #fff8f3;
  border-radius: 8px;
  margin-left: 8px;
  height: 88px;
}

.flex-box {
  justify-content: center;
  flex: 1;

  .item {
    width: 100%;

    .flex {
      justify-content: center;

      .sub-item {
        flex: 1;
      }
    }
  }
}
</style>
