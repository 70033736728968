import request from "@/core/services/axios";
export const getTopData = (params) => {
  // 获取新高考校级报告顶部数据
  return request({
    url: `/report/statnewgaokaoschoolreport/getschoolreporttopinfo`,
    method: "GET",
    params: params,
  });
};
export const subjectcompetitive = (params) => {
  // 校级报告-考情概述-学科竞争力
  return request({
    url: `/report/statnewgaokaoschoolreport/getschoolreportsummary/subjectcompetitive`,
    method: "GET",
    params: params,
  });
};
export const eleccombcompetitive = (params) => {
  // 校级报告-考情概述-组合竞争力
  return request({
    url: `/report/statnewgaokaoschoolreport/getschoolreportsummary/eleccombcompetitive`,
    method: "GET",
    params: params,
  });
};
export const scoredistribution = (params) => {
  // 校级报告-考情概述-班级成绩分布
  return request({
    url: `/report/statnewgaokaoschoolreport/getschoolreportsummary/scoredistribution`,
    method: "POST",
    data: params,
  });
};
export const getschoolreportclasscompetitive = (params) => {
  // 校级报告-考情概述-班级竞争力
  return request({
    url: `/report/statnewgaokaoschoolreport/getschoolreportclasscompetitive`,
    method: "get",
    params: params,
  });
};
export const classscoredistribution = (params) => {
  // 校级报告-考情概述-班级成绩分布
  return request({
    url: `/report/statnewgaokaoschoolreport/getschoolreportclasscompetitive/classscoredistribution`,
    method: "post",
    data: params,
  });
};
export const getschoolreportclasssubjectbalancecompare = (params) => {
  // 校级报告-考情概述-班级学科均衡性对比
  return request({
    url: `/report/statnewgaokaoschoolreport/getschoolreportclasssubjectbalancecompare`,
    method: "get",
    params: params,
  });
};
export const waveStudent = (params) => {
  // 校级报告-考情概述-波动生
  return request({
    url: `/report/statnewgaokaoschoolreport/getschoolreportimportstudent/wave`,
    method: "post",
    data: params,
  });
};
export const topOrBackward = (params) => {
  // 校级报告-考情概述-拔尖生-后进生
  return request({
    url: `/report/statnewgaokaoschoolreport/getschoolreportimportstudent/toporbackward`,
    method: "post",
    params: {
      ...params,
    },
  });
};
export const trackStudent = (params) => {
  // 校级报告-考情概述-跟踪生
  return request({
    url: `/report/statnewgaokaoschoolreport/getschoolreportimportstudent/tracking`,
    method: "get",
    params: params,
  });
};
export const getDevelopment = (params) => {
  // 校级报告-发展性分析
  return request({
    url: `/report/statnewgaokaoschoolreport/getschoolreportdevelopanalysis`,
    method: "get",
    params: params,
  });
};
