import request from "@/core/services/axios";
export function getschoolreporttopinfo(params) {
  // 获取顶部数据
  return request({
    url: `/report/statschoolreport/getschoolreporttopinfo`,
    method: "get",
    params: params,
  });
}
export function downloadschoolpdfreport(params) {
  // 下载校级报告PDF文档
  return request({
    url: `/report/statschoolreport/downloadschoolpdfreport`,
    method: "get",
    params: params,
  });
}
export function getschoolreportclasscompetitive(params) {
  // 校级报告-班级竞争力-班级指标对比
  return request({
    url: `/report/statschoolreport/getschoolreportclasscompetitive`,
    method: "get",
    params: params,
  });
}
export function getstatInfo(params) {
  // 获取科目
  return request({
    url: `/report/stat/getstatinfo`,
    method: "get",
    params: params,
  });
}
export function getschoolreportquerycriteria(params) {
  // 校级报告-班级竞争力-查询条件(科目和班级)
  return request({
    url: `/report/statschoolreport/getschoolreportquerycriteria`,
    method: "get",
    params: params,
  });
}
export function getschoolreportclasscompetitiveText(params) {
  // 校级报告-班级竞争力-文本信息
  return request({
    url: `/report/statschoolreport/getschoolreportclasscompetitive/text`,
    method: "get",
    params: params,
  });
}
export function classscoredistribution(params) {
  // 校级报告-班级竞争力-班级成绩分布
  return request({
    url: `/report/statschoolreport/getschoolreportclasscompetitive/classscoredistribution`,
    method: "post",
    data: params,
  });
}
export function getschoolreportclassandcubject(params) {
  // 校级报告-班级&学科
  return request({
    url: `/report/statschoolreport/getschoolreportclassandcubject`,
    method: "post",
    data: params,
  });
}
export function getSchoolReportSubjectContribution(params) {
  // 校级报告-学科贡献分析-学科贡献
  return request({
    url: `/report/statschoolreport/getSchoolReportSubjectContribution`,
    method: "get",
    params: params,
  });
}
export function subjectcompetitive(params) {
  // 校级报告-考情概述-校学科竞争力
  return request({
    url: `/report/statschoolreport/getschoolreportsummary/subjectcompetitive`,
    method: "get",
    params: params,
  });
}
export function scoredistribution(params) {
  // 校级报告-考情概述-成绩分布
  return request({
    url: `/report/statschoolreport/getschoolreportsummary/scoredistribution`,
    method: "post",
    data: params,
  });
}
export function getschoolreportimportstudentTracking(params) {
  // 校级报告-重点关注学生-跟踪生
  return request({
    url: `/report/statschoolreport/getschoolreportimportstudent/tracking`,
    method: "get",
    params: params,
  });
}
export function getschoolreportimportstudentCriticalquery(params) {
  // 校级报告-重点关注学生-临界生查询条件
  return request({
    url: `/report/statschoolreport/getschoolreportimportstudent/criticalquery`,
    method: "get",
    params: params,
  });
}
export function getschoolreportimportstudentCritical(params) {
  // 校级报告-重点关注学生-临界生
  return request({
    url: `/report/statschoolreport/getschoolreportimportstudent/critical`,
    method: "get",
    params: params,
  });
}
export function getstatcomparelist(params) {
  // 根据statId查询考试列表
  return request({
    url: `/report/stat/getstatstudentwavecomparelist`,
    method: "post",
    data: params,
  });
}
export function toporbackward(params) {
  // 校级报告-重点关注学生-拔尖生-后进生
  // type 	查询类型 1：拔尖生查询，2：后进生查询

  return request({
    url: `/report/statschoolreport/getschoolreportimportstudent/toporbackward`,
    method: "post",
    params: params,
  });
}
export function getschoolreportimportstudentWave(params) {
  // 校级报告-重点关注学生-波动生
  return request({
    url: `/report/statschoolreport/getschoolreportimportstudent/wave`,
    method: "post",
    data: params,
  });
}
export function getschoolreportdevelopanalysis(params) {
  // 校级报告-发展性分析
  return request({
    url: `/report/statschoolreport/getschoolreportdevelopanalysis/v2`,
    method: "GET",
    params: params,
  });
}
export function exportSchoolReport(params) {
  // 校级报告-导出
  // type 校级报告导出：0-全部，1-校学科竞争力,2-班级指标对比,3-班级&学科,4-学科贡献分析,5-重点关注学生
  return request({
    url: `/report/statschoolreport/export`,
    method: "post",
    data: params,
  });
}
export function exportSchoolNewReport(params) {
  // 校级报告-新高考-导出
  // type 校级报告导出：0-全部，1-校学科竞争力,2-班级指标对比,3-班级&学科,4-学科贡献分析,5-重点关注学生
  return request({
    url: `/report/statnewgaokaoschoolreport/export`,
    method: "post",
    data: params,
  });
}
