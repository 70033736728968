<template>
  <div ref="overview" v-lazyChart="{ fn: getData }" class="container">
    <exportBtn
      :request-func="requestFunc"
      :params="{ id: $route.query.id, type: 1 }"
    >
      <div class="title">考情概述</div>
    </exportBtn>
    <div class="sub-title">学科竞争力</div>
    <div class="operate">
      <div class="filter">
        <a-select
          v-if="type == 3"
          v-model="subjectId"
          not-found-content="暂无数据"
          style="width: 120px; margin-right: 10px"
          @change="subjectIdChange"
        >
          <a-select-option
            v-for="(item, index) in subjectList"
            :key="index"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>

        <div v-if="type != 1">
          数据源：
          <a-radio-group
            v-model="dataSource"
            name="radioGroup"
            @change="dataSourceChange"
          >
            <a-radio :value="0"> 原始分 </a-radio>
            <template v-if="type == 3">
              <a-radio v-if="![1, 2, 3, 4, 8].includes(subjectId)" :value="1">
                赋分
              </a-radio>
            </template>
            <template v-else>
              <a-radio :value="1"> 赋分 </a-radio>
            </template>
          </a-radio-group>
        </div>
      </div>
      <a-radio-group v-model="type" button-style="solid" @change="typeChange">
        <a-radio-button :value="1"> 均分分析 </a-radio-button>
        <a-radio-button v-if="ratios.length" :value="2"> 比率 </a-radio-button>
        <!-- <a-radio-button :value="3"> 前N名 </a-radio-button> -->
      </a-radio-group>
    </div>

    <a-table
      :key="Math.random() + type"
      :columns="columns"
      :data-source="tableData"
      bordered
      :pagination="false"
      style="margin-bottom: 18px"
    >
    </a-table>
    <div v-if="type === 2" class="ratioFilter">
      <a-select v-model="ratio" style="width: 220px" @change="setRatioSingle">
        <a-select-option
          v-for="(item, index) in ratioOptions"
          :key="index"
          :value="item.name"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
    </div>
    <template v-if="defineOptions">
      <chart :key="chartKey" :define-options="defineOptions" :series="series" />
    </template>
    <no-data v-else />
    <div v-if="type === 1" class="tip">
      <div class="intent">注：</div>
      <div class="text">
        <div>除赋分均分外，以上数据结果均以原始分进行统计运算。</div>
        <div>难度=平均分/满分值，分值越大难度越小，分值越小难度越大；</div>
        <div>
          区分度=（排名前27%均分-排名后27%均分）/满分值，分值越大区分度越大，分值越小区分度越小
        </div>
        <div>
          标准差表示一组数值的离散程度，标准差越大，表示该组数值与均值的偏差越大。
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import chart from "../../../components/charts";
import { ratio, topSetction } from "./columns";
import { subjectcompetitive } from "@/core/api/newExamAcademic/school";
import { getstatsubjectoption } from "@/core/api/academic/common";
import { exportSchoolNewReport } from "@/core/api/academic/schoolReportDetail";
import exportBtn from "@/views/academic/components/exportBtn.vue";
export default {
  components: {
    chart,
    exportBtn,
  },
  data() {
    return {
      series: [],
      type: "0",
      columns: [],
      tableData: [],
      requestFunc: exportSchoolNewReport,
      defineOptions: null,
      ratios: [],
      dataSource: 0,
      chartKey: null,
      subjectId: null,
      subjectList: [],
      originData: [],
      ratioOptions: [],
      ratio: "",
      avg: [
        {
          title: "学科",
          align: "center",
          dataIndex: "subjectName",
        },
        {
          title: "统计人数",
          align: "center",
          dataIndex: "statTotal",
          // customRender: (text, record) => (
          //   <div class="unionTr">
          //     <div>{record.statTotal}</div>
          //     <div>2</div>
          //   </div>
          // ),
        },
        {
          title: "难度",
          align: "center",
          dataIndex: "scoreRateOri",
          customRender: (text, record) => {
            return (record.scoreRateOri / 100).toFixed(2);
          },
        },
        {
          title: "平均分",
          align: "center",
          dataIndex: "averageScoreOri",
        },
        {
          title: "中位数",
          align: "center",
          dataIndex: "medianOri",
        },
        {
          title: "赋分均分",
          align: "center",
          dataIndex: "averageScore",
          customRender: (text, reocrd) => {
            if ([1, 2, 3, 4, 8].includes(reocrd.subjectId)) {
              return "-";
            } else {
              return reocrd.averageScore;
            }
          },
        },
        {
          title: "排名前27%均分",
          align: "center",
          dataIndex: "top27AverageScoreOri",
        },
        {
          title: "排名后27%均分",
          align: "center",
          dataIndex: "after27AverageScoreOri",
        },
        {
          title: "最高分",
          align: "center",
          dataIndex: "highestScoreOri",
        },
        {
          title: "最低分",
          align: "center",
          dataIndex: "lowestScoreOri",
        },
        {
          title: "标准差",
          align: "center",
          dataIndex: "standardDeviationOri",
        },
        {
          title: "区分度",
          align: "center",
          dataIndex: "discriminationOri",
          customRender: (text, record) => record.discriminationOri.toFixed(2),
        },
      ],
    };
  },
  mounted() {
    this.type = 1;
    this.typeChange();
  },
  methods: {
    subjectIdChange() {
      if ([1, 2, 3, 4, 8].includes(this.subjectId)) {
        this.dataSource = 0;
      }
      this.setTopSetction();
    },
    async getSubjectList() {
      const res = await getstatsubjectoption({
        id: this.$route.query.id,
        hasTotal: 0,
        requestPage: 1,
      });
      this.subjectList = res.data.data;
      if (res.data.data.length) {
        this.subjectId = res.data.data[0].id;
        this.setTopSetction();
      }
    },
    async getData(loadingInstance, io) {
      const res = await subjectcompetitive({ id: this.$route.query.id }).catch(
        () => {
          this.$nextTick(() => {
            loadingInstance.close();
            io.unobserve(this.$refs.overview);
          });
        }
      );
      let main = res.data.data.filter((item) =>
        [1, 2, 3, 4, 8].includes(item.subjectId)
      );
      let other = res.data.data.filter(
        (item) => ![1, 2, 3, 4, 8].includes(item.subjectId)
      );
      this.originData = [...main, ...other];
      this.tableData = [...this.originData];
      if (this.tableData.length) {
        this.ratios = this.tableData[0].ratios;
      }
      this.typeChange();
      this.$nextTick(() => {
        loadingInstance.close();
        io.unobserve(this.$refs.overview);
      });
    },
    typeChange() {
      this.series = [];
      this.defineOptions = null;
      this.tableData = [...this.originData];
      if (this.type === 1) {
        this.setAvg();
      } else if (this.type === 2) {
        this.setRatio();
      } else if (this.type === 3) {
        this.getSubjectList();
      }
    },
    dataSourceChange() {
      this.series = [];
      this.defineOptions = null;
      if (this.dataSource == 0) {
        this.tableData = [...this.originData];
      } else {
        this.tableData = [
          ...this.originData.filter(
            (item) => ![0, 1, 2, 3, 4, 8].includes(item.subjectId)
          ),
        ];
      }
      if (this.type === 1) {
        this.setAvg();
      } else if (this.type === 2) {
        this.setRatio();
      } else if (this.type === 3) {
        this.setTopSetction();
      }
    },
    setAvg() {
      this.columns = this.avg;

      this.defineOptions = {
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          data: [...this.tableData.map((item) => item.subjectName)],
          color: ["#619AF1", "#8FB8F5"],
        },
      };
      this.series = [
        {
          name: "原始分",
          type: "bar",
          color: "#619AF1",
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
          barMaxWidth: 40,
          data: [...this.tableData.map((item) => item["averageScoreOri"])],
        },
        {
          name: "赋分",
          color: "#8FB8F5",
          type: "bar",
          label: {
            show: true,
            position: "top",
            color: "inherit",
            formatter: ({ value }) => {
              if (value === 0) {
                return "";
              }
              return value;
            },
          },
          barMaxWidth: 40,
          data: [
            ...this.tableData.map((item) => ({
              value: [1, 2, 3, 4, 8].includes(item.subjectId)
                ? 0
                : item["averageScore"],
            })),
          ],
        },
      ];
      this.chartKey = Math.random();
    },
    setRatioSingle() {
      let key = this.dataSource == 1 ? "proportion" : "proportionOri";
      let currentRatio = this.ratioOptions.find(
        (item) => item.name === this.ratio
      );
      let data = this.tableData.map(
        (item) => item.ratios.find((it) => it.name === this.ratio)[key]
      );
      this.series = [
        {
          name: currentRatio.name,
          type: "bar",
          stack: "subject",
          barMaxWidth: 40,
          data: [...data],
          label: { show: true, position: "top", color: "inherit" },
        },
      ];
      this.chartKey = Math.random();
      this.defineOptions = {
        color: ["#619AF1", "#FF9776", "#6EDBA7", "#FFD766"],
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          data: [...this.tableData.map((item) => item.subjectName)],
        },
      };
    },
    setRatio() {
      if (this.tableData.length > 0) {
        this.ratios = this.tableData[0].ratios;
        this.ratioOptions = this.tableData[0].ratios;
        this.ratio = this.ratioOptions[0].name;
        let ratioCols = this.ratios.map((item, index) => ({
          title: item.name,
          dataIndex: `ratio${index}`,
          align: "center",
          customRender: (text, record) => {
            let itemData = record.ratios[index];
            if (this.dataSource == 0) {
              return (
                <span>
                  {itemData.totalOri}/{itemData.proportionOri}%
                </span>
              );
            } else if (this.dataSource == 1) {
              return (
                <span>
                  {itemData.total}/{itemData.proportion}%
                </span>
              );
            }
          },
        }));
        this.columns = [...ratio, ...ratioCols];
        this.setRatioSingle();
      }
    },
    setTopSetction() {
      this.tableData = this.originData.filter(
        (item) => item.subjectId === this.subjectId
      );
      if ([1, 2, 3, 4, 8].includes(this.subjectId)) {
        this.dataSource = 0;
      }
      let topSectionsList = [];
      if (this.tableData.length) {
        topSectionsList = this.tableData[0].topSections;
        if (topSectionsList.length) {
          this.defineOptions = {
            color: ["#2E71F6", "#5794FA", "#85BCFC", "#CFE6FF"],
            xAxis: {
              axisTick: {
                show: false,
              },
              type: "category",
              data: [...topSectionsList.map((item) => item.name)],
            },
          };
        }

        let key = this.dataSource == 1 ? "proportion" : "proportionOri";
        let subject = this.tableData.find(
          (item) => item.subjectId === this.subjectId
        );
        this.series = [
          {
            name: subject.subjectName,
            type: "bar",
            data: [...subject.topSections.map((item) => item[key])],
            barMaxWidth: 40,
            label: {
              show: true,
              position: "top",
              color: "inherit",
              formatter({ value }) {
                return value + "%";
              },
            },
          },
        ];
        this.chartKey = Math.random();
      }
      let topSectionsCol = topSectionsList.map((item, index) => ({
        title: item.name,
        dataIndex: `topSetctions${index}`,
        align: "center",
        customRender: (text, record) => {
          let itemData = record.topSections[index];
          if (this.dataSource == 0) {
            return (
              <span>
                {itemData.totalOri}/{itemData.proportionOri}%
              </span>
            );
          } else if (this.dataSource == 1) {
            return (
              <span>
                {itemData.total}/{itemData.proportion}%
              </span>
            );
          }
        },
      }));
      this.columns = [...topSetction, ...topSectionsCol];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../new-exam.module.scss";

.operate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;

  .filter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.ratioFilter {
  text-align: right;
  margin-bottom: 18px;
}

.unionTr {
  & :first-child {
    box-sizing: border-box;
    padding: 5px 10px;
  }

  & :nth-child(2) {
    padding: 5px 10px;
    box-sizing: border-box;
    border-top: 1px solid #e8e8e8;
  }
}

::v-deep .unionTable td {
  padding: 0 !important;
}
</style>
