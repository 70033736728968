<template>
  <div
    ref="classCompetitiveness"
    v-lazyChart="{ fn: getCondition }"
    class="container"
  >
    <div class="title">班级竞争力</div>
    <div class="filter-box">
      <div class="subjectList">
        <div
          v-for="(item, index) in subjectList"
          :key="index"
          :class="['subject', search.subjectId === item.id ? 'active' : '']"
          @click="handleClick(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="operate">
        <a-select
          v-model="search.cate"
          not-found-content="暂无数据"
          style="width: 120px; margin-right: 8px"
          :disabled="cateDisabled"
          @change="handleChange"
        >
          <a-select-option
            v-for="(item, index) in eleccateoptionList"
            :key="index"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <eleccomb
          style="margin-right: 8px"
          :eleccomb-list="resEleccombList"
          @change="resEleccomChange"
        />
        <classNums
          ref="classNums"
          style="margin-right: 8px"
          :placeholder="'选择' + getClassType(search.subjectId) + '班'"
          :request-page="1"
          :class-num-list="classNumList"
          @change="classNumChange"
        />
      </div>
    </div>
    <div class="category">
      <div>
        <!-- <template
          v-if="
            [2, 3].includes(search.category) &&
            ![1, 2, 3, 4, 8].includes(search.subjectId)
          "
        >
          <span>数据源：</span>
          <a-radio-group
            v-model="search.dataSource"
            :options="plainOptions"
            @change="dataSourceChange"
          />
        </template> -->
      </div>
      <a-radio-group
        v-model="search.category"
        button-style="solid"
        @change="categoryChange"
      >
        <a-radio-button :value="1"> 均分分析 </a-radio-button>
        <a-radio-button :value="2"> 比 率 </a-radio-button>
        <a-radio-button
          v-if="search.subjectId != 0 && topSections.length"
          :value="3"
        >
          前N名
        </a-radio-button>
        <a-radio-button :value="4"> 更多指标 </a-radio-button>
      </a-radio-group>
    </div>
    <exTable
      :key="tableKey"
      v-loading="loading"
      :columns="columns"
      :data-source="tableData"
      :show-tail-number="2"
    >
    </exTable>
    <div
      v-if="search.category == 1 && ![1, 2, 3, 4, 8].includes(search.subjectId)"
      class="dataSource"
    >
      数据源：
      <a-radio-group
        v-model="search.dataSource"
        :options="plainOptions"
        @change="categoryChange"
      />
    </div>
    <div v-if="[2, 3, 4].includes(search.category)" class="select">
      <a-select v-model="select" style="width: 220px" @change="selectChange">
        <a-select-option
          v-for="(item, index) in selectOptions"
          :key="index"
          :value="item[selectValue]"
        >
          {{ item[selectLabel] }}
        </a-select-option>
      </a-select>
    </div>
    <chart
      :key="chartKey"
      :define-options="defineOptions"
      :series="series"
      :data-zoom="8"
    />
  </div>
</template>
<script>
import {
  getstatsubjectoption,
  getstateleccomboptionV2,
  getstatclassoption,
  getstateleccateoption,
} from "@/core/api/academic/common";
import classNums from "../../../components/classNums.vue";
import chart from "../../../components/charts.vue";
import exTable from "../../../components/exTable.vue";
import { getClassType } from "../../../func";
import eleccomb from "../../../components/eleccomb.vue";
import { getschoolreportclasscompetitive } from "@/core/api/newExamAcademic/union/school";
import bus from "../../eventBus";

export default {
  components: {
    classNums,
    eleccomb,
    exTable,
    chart,
  },
  data() {
    return {
      selectLabel: null,
      selectValue: null,
      select: null,
      selectOptions: [],
      tableData: [],
      resEleccombIds: [],
      getClassType: getClassType,
      subjectList: [],
      plainOptions: [
        { label: "原始分", value: 0 },
        { label: "赋分", value: 1 },
      ],
      cateDisabled: false,
      search: {
        subjectId: 0,
        type: 1,
        category: 1,
        dataType: "standardDeviation",
        dataSource: 0,
        cate: 0,
      },
      resEleccombList: [],
      defineOptions: {},
      series: [],
      BoxPlotXAxis: [],
      allChecked: false,
      indeterminate: false,
      columns: [],
      tableKey: Math.random(),
      avg: [
        {
          dataIndex: "schoolRank",
          scopedSlots: { title: "schoolRank" },
          align: "center",
          fixed: true,
          customRender: (test, record) =>
            ["整体（大类）", "学校（大类）"].includes(record.classNum)
              ? "-"
              : record.schoolRank,
        },
        {
          dataIndex: "unionRank",
          scopedSlots: { title: "unionRank" },
          align: "center",
          fixed: true,
          customRender: (test, record) =>
            ["整体（大类）", "学校（大类）"].includes(record.classNum)
              ? "-"
              : record.unionRank,
        },
        {
          dataIndex: "classNum",
          title: "班级",
          align: "center",
        },
        {
          dataIndex: "eleccombName",
          title: "选考组合",
          align: "center",
        },
        {
          dataIndex: "teacherName",
          title: "班主任",
          align: "center",
        },
        {
          dataIndex: "statTotal",
          title: "统计人数",
          align: "center",
        },
        {
          dataIndex: "averageScoreOri",
          scopedSlots: { title: "averageScoreOri" },
          align: "center",
        },
        {
          dataIndex: "averageScore",
          title: "赋分均分",
          align: "center",
        },
        {
          dataIndex: "medianOri",
          scopedSlots: { title: "medianOri" },
          align: "center",
        },

        {
          dataIndex: "highestScoreOri",
          scopedSlots: { title: "highestScoreOri" },
          align: "center",
        },
        {
          dataIndex: "lowestScoreOri",
          scopedSlots: { title: "lowestScoreOri" },
          align: "center",
        },

        {
          dataIndex: "top27AverageScoreOri",
          scopedSlots: { title: "top27AverageScoreOri" },
          align: "center",
          customRender: (test, record) =>
            ["整体（大类）"].includes(record.classNum)
              ? "-"
              : record.top27AverageScoreOri,
        },
        {
          dataIndex: "after27AverageScoreOri",
          scopedSlots: { title: "after27AverageScoreOri" },
          align: "center",
          customRender: (test, record) =>
            ["整体（大类）"].includes(record.classNum)
              ? "-"
              : record.after27AverageScoreOri,
        },
        {
          dataIndex: "discriminationOri",
          title: "区分度",
          align: "center",
          customRender: (test, record) => {
            if (["整体（大类）"].includes(record.classNum)) {
              return "-";
            }
            return record.discriminationOri
              ? record.discriminationOri.toFixed(2)
              : "";
          },
        },
      ],
      ratioCol: [
        {
          dataIndex: "classNum",
          title: "班级",
          fixed: true,
          align: "center",
          width: 120,
          customRender: (text, record) => {
            if (["学校（大类）", "整体（大类）"].includes(record.classNum)) {
              return {
                children: text,
                attrs: {
                  colSpan: 3,
                },
              };
            }
            return text;
          },
        },
        {
          dataIndex: "eleccombName",
          title: "选考组合",
          fixed: true,
          width: 120,
          align: "center",
          customRender: (text, record) => {
            if (["学校（大类）", "整体（大类）"].includes(record.classNum)) {
              return {
                children: text,
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return text;
          },
        },
        {
          dataIndex: "teacherName",
          title: "班主任",
          fixed: true,
          width: 120,
          align: "center",
          customRender: (text, record) => {
            if (["学校（大类）", "整体（大类）"].includes(record.classNum)) {
              return {
                children: text,
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return text;
          },
        },

        {
          dataIndex: "statTotal",
          title: "统计人数",
          width: 120,
          fixed: true,
          align: "center",
        },
      ],
      moreCol: [
        {
          dataIndex: "classNum",
          title: "班级",
          fixed: true,
          align: "center",
          customRender: (text, record) => {
            if (["学校（大类）", "整体（大类）"].includes(record.classNum)) {
              return {
                children: text,
                attrs: {
                  colSpan: 3,
                },
              };
            }
            return text;
          },
        },
        {
          dataIndex: "eleccombName",
          title: "选考组合",
          fixed: true,
          align: "center",
          width: 120,
          customRender: (text, record) => {
            if (["学校（大类）", "整体（大类）"].includes(record.classNum)) {
              return {
                children: text,
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return text;
          },
        },
        {
          dataIndex: "teacherName",
          title: "班主任",
          fixed: true,
          align: "center",
          customRender: (text, record) => {
            if (["学校（大类）", "整体（大类）"].includes(record.classNum)) {
              return {
                children: text,
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return text;
          },
        },

        {
          dataIndex: "statTotal",
          title: "统计人数",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "highestScoreOri",
          scopedSlots: { title: "highestScoreOri" },
          align: "center",
          customRender: (text, record) => record["highestScoreOri"],
        },
        {
          dataIndex: "lowestScoreOri",
          scopedSlots: { title: "lowestScoreOri" },
          align: "center",
          customRender: (text, record) => record["lowestScoreOri"],
        },
        {
          dataIndex: "rangeScoreOri",
          scopedSlots: { title: "rangeScoreOri" },
          align: "center",
          customRender: (text, record) => record["rangeScoreOri"],
        },
        {
          dataIndex: "medianOri",
          align: "center",
          scopedSlots: { title: "medianOri" },
          customRender: (text, record) => record["medianOri"],
        },
        {
          dataIndex: "overAverageRateUnionOri",
          align: "center",
          scopedSlots: { title: "unionOverAverageRateOri" },
          customRender: (text, record) => record["overAverageRateUnionOri"],
        },
        {
          dataIndex: "overAverageRateOri",
          align: "center",
          scopedSlots: { title: "schoolOverAverageRateOri" },
          customRender: (text, record) => record["overAverageRateOri"],
        },
        {
          dataIndex: "standardDeviationOri",
          scopedSlots: { title: "standardDeviationOri" },
          align: "center",
          customRender: (text, record) => record["standardDeviationOri"],
        },
        {
          dataIndex: "coefficientOfVariationOri",
          scopedSlots: { title: "coefficientOfVariationOri" },
          align: "center",
          customRender: (text, record) => record["coefficientOfVariationOri"],
        },
      ],
      checkClassNum: [],
      classNumList: [],
      loading: false,
      colSpanNum: 3,
      chartKey: Math.random(),
      ratios: [],
      otherOptions: [
        {
          label: "最高分",
          value: "highestScore",
        },
        {
          label: "最低分",
          value: "lowestScore",
        },
        {
          label: "全距",
          value: "rangeScore",
        },
        {
          label: "中位数",
          value: "median",
        },
        {
          label: "超均率",
          value: "overAverageRateUnion",
        },
        {
          label: "超均率（校）",
          value: "overAverageRate",
        },
        {
          label: "标准差",
          value: "standardDeviation",
        },
        {
          label: "差异系数",
          value: "coefficientOfVariation",
        },
      ],
      subjectAvg: [
        {
          dataIndex: "schoolRank",
          title: "校排名",
          align: "center",
          fixed: true,
          customRender: (text, record) => {
            if (["学校（大类）", "整体（大类）"].includes(record.classNum)) {
              return {
                children: text,
                attrs: {
                  colSpan: 4,
                },
              };
            }
            if ((["上一名班级"], ["下一名班级"].includes(record.classNum))) {
              return {
                children: record.classNum,
                attrs: {
                  colSpan: 3,
                },
              };
            }
            return text;
          },
        },
        {
          dataIndex: "unionRank",
          title: "整体排名",
          align: "center",
          fixed: true,
          customRender: (text, record) => {
            if (["学校（大类）", "整体（大类）"].includes(record.classNum)) {
              return {
                children: text,
                attrs: {
                  colSpan: 0,
                },
              };
            }
            if ((["上一名班级"], ["下一名班级"].includes(record.classNum))) {
              return {
                children: record.classNum,
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return text;
          },
        },
        {
          dataIndex: "classNum",
          title: "班级",
          align: "center",
          fixed: true,
          customRender: (text, record) => {
            if (["学校（大类）", "整体（大类）"].includes(record.classNum)) {
              return {
                children: text,
                attrs: {
                  colSpan: 0,
                },
              };
            }
            if ((["上一名班级"], ["下一名班级"].includes(record.classNum))) {
              return {
                children: record.classNum,
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return text;
          },
        },
        {
          dataIndex: "eleccombName",
          title: "选考组合",
          align: "center",
          customRender: (text, record) => {
            if (["学校（大类）", "整体（大类）"].includes(record.classNum)) {
              return {
                children: text,
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return text;
          },
        },

        {
          dataIndex: "teacherName",
          title: "班主任",
          align: "center",
          customRender: (text) => (text ? text : "-"),
        },
        {
          dataIndex: "statTotal",
          title: "统计人数",
          align: "center",
        },
        {
          dataIndex: "averageScoreOri",
          scopedSlots: { title: "averageScoreOri" },
          align: "center",
        },
        {
          dataIndex: "averageScore",
          title: "赋分均分",
          align: "center",
          customRender: (text, record) => {
            if ([1, 2, 3, 4, 8].includes(record.subjectId)) {
              return "-";
            } else {
              return record.averageScore;
            }
          },
        },
        {
          dataIndex: "medianOri",
          scopedSlots: { title: "medianOri" },
          align: "center",
        },

        {
          dataIndex: "highestScoreOri",
          scopedSlots: { title: "highestScoreOri" },
          align: "center",
        },
        {
          dataIndex: "lowestScoreOri",
          scopedSlots: { title: "lowestScoreOri" },
          align: "center",
        },

        {
          dataIndex: "top27AverageScoreOri",
          scopedSlots: { title: "top27AverageScoreOri" },
          align: "center",
          customRender: (text, record) =>
            record.classNum == "整体" ? "-" : text,
        },
        {
          dataIndex: "after27AverageScoreOri",
          scopedSlots: { title: "after27AverageScoreOri" },
          align: "center",
          customRender: (text, record) =>
            record.classNum == "整体" ? "-" : text,
        },
        {
          dataIndex: "discriminationOri",
          title: "区分度",
          align: "center",
          customRender: (text, record) => {
            if (record.classNum === "整体") {
              return "-";
            } else {
              record.discriminationOri
                ? record.discriminationOri.toFixed(2)
                : "";
            }
          },
        },
      ],
      topSections: [],
      eleccateoptionList: [],
    };
  },
  created() {},
  methods: {
    async getCondition(loadingInstance, io) {
      const res = await getstatsubjectoption({
        id: this.$route.query.id,
        hasTotal: 1,
        requestPage: 1,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.subjectList = res.data.data;

      if (this.subjectList.length) {
        this.search.subjectId = res.data.data[0].id;
        bus.$emit("subjectChange", res.data.data[0]);

        this.getstateleccateoptionList();
      }
      this.closeLoading(loadingInstance, io);
    },
    async getstateleccateoptionList() {
      const res = await getstateleccateoption({
        id: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
        subjectId: this.search.subjectId,
        hasAll: 0,
      });
      this.eleccateoptionList = res.data.data;
      if (res.data.data.length) {
        this.search.cate = res.data.data[0].id;
      }
      this.getEleccombList();
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.classCompetitiveness);
        });
      }
    },
    handleClick(item) {
      this.search.subjectId = item.id;

      if (item.id == 0 && this.search.category == 3) {
        this.search.category = 1;
      }
      this.getstateleccateoptionList();
    },
    dataSourceChange() {
      const enmu = {
        1: this.setAvgChart,
        2: this.ratioChart,
        3: this.setTopSectionChart,
        4: this.setOtherChart,
      };
      enmu[this.search.category]();
    },
    createCateData() {
      const nototal = this.tableData.filter(
        (item) =>
          !["学校（大类）", "整体（大类）", "学校", "整体"].includes(
            item.classNum
          )
      );
      const school = this.tableData.find((item) =>
        ["学校（大类）", "学校"].includes(item.classNum)
      );
      const union = this.tableData.find((item) =>
        ["整体（大类）", "整体"].includes(item.classNum)
      );
      return { nototal, school, union };
    },
    setAvgChart() {
      const { nototal, school, union } = this.createCateData();
      this.defineOptions = {
        grid: {
          bottom: "15%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          axisLabel: {
            rotate: 25,
          },
          data: nototal.map((item) => item.classNum + "/" + item.eleccombName),
        },
      };
      this.series = [
        {
          name:
            "班级" + (this.search.dataSource == 0 ? "（原始分）" : "（赋分）"),
          type: "bar",
          barMaxWidth: 40,
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
          color: "#2474ED",
          data: nototal.map((item) =>
            this.search.dataSource == 0
              ? item.averageScoreOri
              : item.averageScore
          ),
        },
        {
          name:
            "学校" + (this.search.dataSource == 0 ? "（原始分）" : "（赋分）"),
          type: "line",
          color: "#E8684A",
          data: nototal.map(() =>
            this.search.dataSource == 0
              ? school.averageScoreOri
              : school.averageScore
          ),
        },
      ];
      if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
        this.series.push({
          name:
            "整体" + (this.search.dataSource == 0 ? "（原始分）" : "（赋分）"),
          type: "line",
          color: "#F56C6B",
          data: nototal.map(() =>
            this.search.dataSource == 0
              ? union.averageScoreOri
              : union.averageScore
          ),
        });
      }
      this.chartKey = Math.random();
    },
    ratioChart() {
      const { nototal, school, union } = this.createCateData();

      this.defineOptions = {
        grid: {
          containLabel: true,
        },
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          data: nototal.map((item) => item.classNum + "/" + item.eleccombName),
        },
      };
      this.series = [
        {
          name: "班级",
          type: "bar",
          barMaxWidth: 40,
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
          color: "#2474ED",
          data: nototal.map((item) => {
            let data = item.ratios.find((it) => it.name == this.select);

            return this.search.dataSource == 1 ? data.totalOri : data.total;
          }),
        },
        {
          name: "学校",
          type: "line",
          color: "#E8684A",
          data: nototal.map(() => {
            let data = school.ratios.find((it) => it.name == this.select);
            return this.search.dataSource == 1 ? data.totalOri : data.total;
          }),
        },
      ];
      if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
        this.series.push({
          name: "整体",
          type: "line",
          color: "#F56C6B",
          data: nototal.map(() => {
            let data = union.ratios.find((it) => it.name == this.select);
            return this.search.dataSource == 1 ? data.totalOri : data.total;
          }),
        });
      }
      this.chartKey = Math.random();
    },
    setTopSectionChart() {
      const { nototal, school, union } = this.createCateData();

      this.defineOptions = {
        grid: {
          containLabel: true,
        },
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          data: nototal.map((item) => item.classNum + "/" + item.eleccombName),
        },
      };
      this.series = [
        {
          name: "班级",
          type: "bar",
          barMaxWidth: 40,
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
          color: "#2474ED",
          data: nototal.map((item) => {
            let data = item.topSections.find((it) => it.name == this.select);
            return this.search.dataSource == 1 ? data.total : data.totalOri;
          }),
        },
        {
          name: "学校",
          type: "line",
          color: "#E8684A",
          data: nototal.map(() => {
            let data = school.topSections.find((it) => it.name == this.select);
            return this.search.dataSource == 1 ? data.total : data.totalOri;
          }),
        },
      ];
      if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
        this.series.push({
          name: "整体",
          type: "line",
          color: "#F56C6B",
          data: nototal.map(() => {
            let data = union.topSections.find((it) => it.name == this.select);
            return this.search.dataSource == 1 ? data.total : data.totalOri;
          }),
        });
      }
      this.chartKey = Math.random();
    },
    setOtherChart() {
      const { nototal, school, union } = this.createCateData();

      this.defineOptions = {
        grid: {
          containLabel: true,
        },
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          data: nototal.map((item) => item.classNum + "/" + item.eleccombName),
        },
      };
      this.series = [
        {
          name: "班级",
          type: "bar",
          barMaxWidth: 40,
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
          color: "#2474ED",
          data: nototal.map((item) => item[this.select + "Ori"]),
        },
        {
          name: "学校",
          type: "line",
          color: "#E8684A",
          data: nototal.map(() => school[this.select + "Ori"]),
        },
      ];
      if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
        this.series.push({
          name: "整体",
          type: "line",
          color: "#F56C6B",
          data: nototal.map(() => union[this.select + "Ori"]),
        });
      }
      this.chartKey = Math.random();
    },
    async getEleccombList() {
      const res = await getstateleccomboptionV2({
        id: this.$route.query.id,
        subjectId: this.search.subjectId == "0" ? null : this.search.subjectId,
        eleccateId: this.search.cate,
        viewSchoolId: this.$route.query.schoolId,
        hasAll: 0,
      });
      this.resEleccombList = res.data.data;
      this.allChecked = true;
      this.resEleccombIds = this.resEleccombList.map((item) => item.id);
      const subject = this.subjectList.find(
        (item) => item.id == this.search.subjectId
      );
      bus.$emit("subjectChange", {
        subject: subject,
        eleccombs: this.resEleccombIds.join(","),
      });
      this.getClassNumList();
    },
    async getClassNumList() {
      const res = await getstatclassoption({
        id: this.$route.query.id,
        requestPage: this.requestPage,
        // eleccateSubjectId: this.search.cate,
        viewSchoolId: this.$route.query.schoolId,
        eleccombs: this.resEleccombIds.join(","),
        subjectId: this.search.subjectId,
      });
      this.classNumList = res.data.data.map((item) => item.name);
      this.checkClassNum = res.data.data.map((item) => item.name);
      this.$refs.classNums.value = this.checkClassNum;
      this.getData();
    },
    async getData() {
      const res = await getschoolreportclasscompetitive({
        id: this.$route.query.id,
        classNums: this.checkClassNum.join(","),
        eleccombs: this.resEleccombIds.join(","),
        subjectId: this.search.subjectId,
        isAllClass: 0,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.tableData = res.data.data;
      if (res.data.data.length) {
        this.topSections = res.data.data[0].topSections || [];
        this.ratios = res.data.data[0].ratios || [];
      }
      this.categoryChange();
    },
    classNumChange(val) {
      this.checkClassNum = val;
      this.getData();
    },
    resEleccomChange(checkedList) {
      this.resEleccombIds = checkedList;
      const subject = this.subjectList.find(
        (item) => item.id == this.search.subjectId
      );
      bus.$emit("subjectChange", {
        subject: subject,
        eleccombs: this.resEleccombIds.join(","),
      });
      this.getData();
    },
    handleChange() {
      this.getEleccombList();
    },
    selectChange() {
      switch (this.search.category) {
        case 2:
          this.ratioChart();
          break;
        case 3:
          this.setTopSectionChart();
          break;
        case 4:
          this.setOtherChart();
          break;
      }
    },
    categoryChange() {
      const enmu = {
        1: this.setAvg,
        2: this.setRatio,
        3: this.setTopSection,
        4: this.setOther,
      };
      if (
        [2, 3, 4].includes(this.search.category) &&
        this.search.subjectId != 0
      ) {
        this.colSpanNum = 2;
      } else {
        this.colSpanNum = 3;
      }
      enmu[this.search.category]();
    },
    setAvg() {
      if (this.search.subjectId == 0) {
        if (this.$parent.topData.isSchoolDisplayReportClassRank == 1) {
          this.columns = [...this.avg];
        } else {
          this.columns = this.avg.filter(
            (item) => !["schoolRank", "unionRank"].includes(item.dataIndex)
          );
        }
      } else {
        let cols = [];
        if (this.$parent.topData.isSchoolDisplayReportClassRank == 1) {
          cols = [...this.subjectAvg];
        } else {
          cols = this.subjectAvg.filter(
            (item) => !["schoolRank", "unionRank"].includes(item.dataIndex)
          );
        }
        this.columns = cols.map((item) => {
          if (item.dataIndex == "teacherName") {
            return {
              ...item,
              title: "授课老师",
            };
          }
          return item;
        });
      }
      this.setAvgChart();
      this.tableKey = Math.random();
    },
    setRatio() {
      this.selectLabel = "name";
      this.selectValue = "name";
      this.selectOptions = [...this.ratios];
      if (this.ratios.length) {
        this.select = this.ratios[0].name;
      }
      let ratioCol = this.ratioCol.map((item) => {
        if (this.search.subjectId != 0 && item.dataIndex == "teacherName") {
          return {
            ...item,
            title: "授课老师",
          };
        }
        return item;
      });
      // if (this.search.subjectId != 0) {
      //   ratioCol = this.ratioCol.filter(
      //     (item) => item.dataIndex != "eleccombName"
      //   );
      // }
      this.columns = [
        ...ratioCol,
        ...this.ratios.map((item, index) => {
          return {
            dataIndex: "ratio" + index,
            title: item.name,
            align: "center",
            children: [
              {
                dataIndex: item.name + "total" + index,
                align: "center",
                title: "人数",
                customRender: (text, record) => {
                  let obj = record.ratios.find((it) => it.name == item.name);
                  if (obj) {
                    return obj.total;
                  }
                  return "";
                },
              },
              {
                dataIndex: item.name + "proportion" + index,
                align: "center",
                title: "占比（%）",
                customRender: (text, record) => {
                  let obj = record.ratios.find((it) => it.name == item.name);
                  if (obj) {
                    return obj.proportion;
                  }
                  return "";
                },
              },
            ],
          };
        }),
      ];
      this.ratioChart();
      this.tableKey = Math.random();
    },
    setTopSection() {
      this.selectLabel = "name";
      this.selectValue = "name";
      this.selectOptions = [...this.topSections];
      if (this.topSections.length) {
        this.select = this.topSections[0].name;
      }
      let cols = this.ratioCol.map((item) => {
        if (this.search.subjectId != 0 && item.dataIndex == "teacherName") {
          return {
            ...item,
            title: "授课老师",
          };
        }
        return item;
      });
      this.columns = [
        ...cols,
        ...this.topSections.map((item, index) => {
          return {
            dataIndex: "topSections" + index,
            title: item.name,
            align: "center",
            children: [
              {
                dataIndex: item.name + "total" + index,
                align: "center",
                title: "人数",
                customRender: (text, record) => {
                  let obj = record.topSections.find(
                    (it) => it.name == item.name
                  );
                  if (obj) {
                    return obj.total;
                  }
                  return "";
                },
              },
              {
                dataIndex: item.name + "proportion" + index,
                align: "center",
                title: "占比（%）",
                customRender: (text, record) => {
                  let obj = record.topSections.find(
                    (it) => it.name == item.name
                  );
                  if (obj) {
                    return obj.proportion;
                  }
                  return "";
                },
              },
            ],
          };
        }),
      ];
      this.setTopSectionChart();
      this.tableKey = Math.random();
    },
    setOther() {
      this.selectLabel = "label";
      this.selectValue = "value";
      this.selectOptions = this.otherOptions;
      this.select = this.otherOptions[0].value;
      let cols = this.moreCol;
      if (this.subjectId != 0) {
        cols.map((item) => {
          if (this.search.subjectId != 0) {
            return {
              ...item,
              title: "授课老师",
            };
          }
          return item;
        });
      }
      this.columns = [...this.moreCol];

      this.setOtherChart();
      this.tableKey = Math.random();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../new-exam.module.scss";
.container {
  .filter-box {
    box-sizing: border-box;
    padding: 16px;
    margin-bottom: 18px;
    border-radius: 8px;
    border: 1px solid #d5d6db;

    .subjectList {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      color: #0a1119;
      font-size: 14px;

      .label {
        margin-right: 8px;
        margin-bottom: 18px;
      }

      .subject {
        border-radius: 4px;
        border: 1px solid #d5d6db;
        box-sizing: border-box;
        padding: 6px 18px;
        cursor: pointer;
        background: #fff;
        margin-bottom: 18px;
        transition: all 0.2s ease-in-out;
      }

      .active {
        color: #fff;
        background: #2474ed;
        border-color: #2474ed;
      }

      .subject + .subject {
        margin-left: 8px;
      }
    }

    .resEleccomb {
      margin-left: 21px;
    }

    .operate {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      //   margin-bottom: 18px;
    }
  }

  .category {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
  }

  .dataSource {
    margin: 18px 0;
  }
}

.checkbox-content {
  display: flex;
  flex-wrap: wrap;

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  .ant-checkbox-wrapper {
    margin-bottom: 8px;
    margin-right: 8px;
  }
}
.select {
  margin: 18px 0;
  text-align: right;
}
</style>
