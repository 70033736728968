<template>
  <div ref="competitiveness" v-lazyChart="{ fn: getData }" class="container">
    <exportBtn
      :request-func="requestFunc"
      :params="{ id: $route.query.id, type: 2 }"
      align="left"
    >
      <div class="sub-title">组合竞争力</div>
    </exportBtn>
    <div class="operate">
      <div>
        <template v-if="type == 4">
          数据源：<a-radio-group
            v-model="dataSource"
            name="radioGroup"
            @change="typeChange"
          >
            <a-radio :value="0"> 原始分 </a-radio>
            <a-radio :value="1"> 赋分 </a-radio>
          </a-radio-group>
        </template>
      </div>
      <a-radio-group
        v-model="type"
        :default-value="1"
        button-style="solid"
        @change="typeChange"
      >
        <a-radio-button :value="1"> 均分分析 </a-radio-button>
        <a-radio-button :value="2"> 四分位分析 </a-radio-button>
        <a-radio-button v-if="ratios.length" :value="3"> 比率 </a-radio-button>
        <a-radio-button :value="4"> 更多指标 </a-radio-button>
      </a-radio-group>
    </div>
    <div v-if="type === 4" class="more-operate">
      <div></div>
      <a-select
        v-model="more"
        :default-value="1"
        not-found-content="暂无数据"
        style="width: 120px"
        @change="moreChange"
      >
        <a-select-option
          v-for="(item, index) in selectOptions"
          :key="'selectOptions' + index"
          :value="item.value"
        >
          {{ item.label }}
        </a-select-option>
      </a-select>
    </div>
    <a-table
      :key="'tableData' + type"
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      bordered
      :row-class-name="setRowClassName"
      style="margin-bottom: 18px"
    >
    </a-table>
    <template v-if="tableData.length">
      <!-- 均分分析 start -->
      <!-- <div v-if="type === 1 || type === 4" class="charts">
        <chart
          v-if="defineOptions1"
          :key="chartKey + type"
          :define-options="defineOptions1"
          :series="series1"
        />
        <chart
          v-if="defineOptions2"
          :key="'defineOptions2' + chartKey + type"
          :define-options="defineOptions2"
          :series="series2"
        />
      </div> -->
      <!-- 均分分析 end -->
      <div v-if="type === 2" class="charts">
        <box-plot
          v-if="defineOptions1"
          :key="Math.random() + type"
          :x-axis="xAxis1"
          :define-options="defineOptions1"
        ></box-plot>
        <no-data v-else />
        <box-plot
          v-if="defineOptions2"
          :key="'defineOptions2' + Math.random() + type"
          show-tooltip
          :x-axis="xAxis2"
          :define-options="defineOptions2"
        ></box-plot>
        <no-data v-else style="width: 50%" />
      </div>
      <!-- 比率 start -->
      <template v-else>
        <chart
          :key="Math.random() + type"
          :define-options="stackOption"
          :series="stackBarSeries"
        />
      </template>
      <!-- 比率 end -->
    </template>
    <no-data v-else />
  </div>
</template>
<script>
import chart from "../../../components/charts.vue";
import BoxPlot from "../../../components/BoxPlot.vue";
import { ratio, selectOptions, avg } from "./columns";
import { eleccombcompetitive } from "@/core/api/newExamAcademic/school";
import { exportSchoolNewReport } from "@/core/api/academic/schoolReportDetail";
import exportBtn from "@/views/academic/components/exportBtn.vue";
export default {
  components: {
    chart,
    BoxPlot,
    exportBtn,
  },
  data() {
    return {
      type: 1,
      requestFunc: exportSchoolNewReport,
      selectOptions: selectOptions,
      series1: [],
      series2: [],
      defineOptions1: null,
      defineOptions2: null,
      BoxPlotSeries: [],
      BoxPlotXAxis: [],
      stackBarSeries: [],
      stackOption: [],
      more: "median",
      columns: [],
      tableData: [],
      dataSource: 0,
      xAxis1: [],
      xAxis2: [],
      chartKey: Math.random(),
      quartering: [
        {
          title: "选科组合",
          align: "center",
          dataIndex: "eleccombName",
        },
        {
          title: "统计人数",
          align: "center",
          dataIndex: "statTotal",
        },
        {
          title: "中位数",
          align: "center",
          dataIndex: "median",
          customRender: (text, record) =>
            record["median" + (this.dataSource == 0 ? "Ori" : "")],
        },
        {
          title: "上限",
          align: "center",
          dataIndex: "upperWhisker",
        },
        {
          title: "最高分",
          align: "center",
          dataIndex: "highestScore",
          customRender: (text, record) => record["highestScore"],
        },
        {
          title: "下限",
          align: "center",
          dataIndex: "lowerWhisker",
        },
        {
          title: "最低分",
          align: "center",
          dataIndex: "lowestScore",
          customRender: (text, record) => record["lowestScore"],
        },
        {
          title: "四分位间距",
          align: "center",
          dataIndex: "interQuartileRange",
        },
        {
          title: "全距/极差",
          align: "center",
          dataIndex: "rangeScore",
        },
      ],
      moreHeader: [
        {
          title: "选科组合 ",
          align: "center",
          dataIndex: "eleccombName",
        },
        {
          title: "统计人数 ",
          align: "center",
          dataIndex: "statTotal",
        },
        {
          title: "平均值",
          align: "center",
          dataIndex: "averageScore",
          customRender: (text, record) =>
            record["averageScore" + (this.dataSource == 0 ? "Ori" : "")],
        },
        {
          title: "中位数",
          align: "center",
          dataIndex: "median",
          customRender: (text, record) =>
            record["median" + (this.dataSource == 0 ? "Ori" : "")],
        },
        {
          title: "最高分",
          align: "center",
          dataIndex: "highestScore",
          customRender: (text, record) =>
            record["highestScore" + (this.dataSource == 0 ? "Ori" : "")],
        },
        {
          title: "最低分",
          align: "center",
          dataIndex: "lowestScore",
          customRender: (text, record) =>
            record["lowestScore" + (this.dataSource == 0 ? "Ori" : "")],
        },
        {
          title: "标准差 ",
          align: "center",
          dataIndex: "standardDeviation",
          customRender: (text, record) =>
            record["standardDeviation" + (this.dataSource == 0 ? "Ori" : "")],
        },
        {
          title: "方差",
          align: "center",
          dataIndex: "variance",
        },
        {
          title: "差异系数",
          align: "center",
          dataIndex: "coefficientOfVariation",
        },
        {
          title: "区分度",
          align: "center",
          dataIndex: "discrimination",
          customRender: (text, record) =>
            record[
              "discrimination" + (this.dataSource == 0 ? "Ori" : "")
            ].toFixed(2),
        },
      ],
      ratios: [],
      topSections: [],
    };
  },
  mounted() {},
  methods: {
    setRowClassName(record) {
      if (
        record.eleccombName === "物理类" ||
        record.eleccombName === "历史类"
      ) {
        return "rowcolor";
      }
    },
    async getData(loadingInstance, io) {
      const res = await eleccombcompetitive({ id: this.$route.query.id }).catch(
        () => {
          if (loadingInstance && io) {
            this.$nextTick(() => {
              loadingInstance.close();
              io.unobserve(this.$refs.competitiveness);
            });
          }
        }
      );
      this.tableData = res.data.data;
      if (this.tableData.length) {
        this.ratios = res.data.data[0].ratios;
        this.topSections = res.data.data[0].topSections;
      }
      this.typeChange();
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.competitiveness);
        });
      }
    },
    moreChange() {
      this.setOther();
    },
    typeChange() {
      this.series1 = [];
      this.series2 = [];
      this.defineOptions1 = null;
      this.defineOptions2 = null;
      this.xAxis1 = this.xAxis2 = [];
      this.stackOption = {};
      this.stackBarSeries = [];
      if (this.type === 1) {
        this.columns = avg;
        this.setAvg();
      } else if (this.type === 2) {
        this.columns = this.quartering;
        this.setQuartering();
      } else if (this.type === 3) {
        this.setRatio();
      } else if (this.type === 4) {
        this.columns = this.moreHeader;
        this.setOther();
      }
    },
    formatterData() {
      let data = this.tableData;
      const physics = data.filter(
        (item) => item && item.eleccombName.indexOf("物") != -1
      );
      const physicsCate = physics.find(
        (item) => item && item.eleccombName === "物理类"
      );
      let physicsCates = physics.filter(
        (item) => item && item.eleccombName !== "物理类"
      );
      physicsCates.sort((a, b) => b.averageScoreOri - a.averageScoreOri);
      const history = data.filter(
        (item) => item && item.eleccombName.indexOf("历") != -1
      );
      const historyCate = history.find(
        (item) => item.eleccombName === "历史类"
      );
      let historyCates = history.filter(
        (item) => item && item.eleccombName !== "历史类"
      );
      historyCates.sort((a, b) => b.averageScoreOri - a.averageScoreOri);
      let historyArr = [historyCate, ...historyCates].filter((item) => item);
      let physicsArr = [physicsCate, ...physicsCates].filter((item) => item);
      this.tableData = [...physicsArr, ...historyArr];
      return {
        physics: [...physicsArr],
        history: [...historyArr],
      };
    },
    setAvg() {
      const { physics, history } = this.formatterData();
      let data = [...physics, ...history];
      if (data.length) {
        this.stackOption = {
          color: ["#619AF1", "#8FB8F5"],
          grid: {
            right: 0,
            left: "5%",
            bottom: 0,
            containLabel: true,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999",
              },
            },
            formatter: (params) => {
              const [score, scoreOri] = params;
              return (
                score.marker +
                score.data.name +
                "：" +
                score.data.value +
                "<br />" +
                scoreOri.marker +
                scoreOri.data.name +
                "：" +
                scoreOri.data.value
              );
            },
          },
          yAxis: {
            type: "value",
          },
          xAxis: [
            {
              type: "category",
              data: [
                ...data.map((item) => {
                  if (["物理类", "历史类"].includes(item.eleccombName)) {
                    return {
                      value: item.eleccombName,
                      textStyle: {
                        color: "#2474ed",
                      },
                    };
                  }
                  return {
                    value: item.eleccombName,
                  };
                }),
              ],
              axisPointer: {
                type: "shadow",
              },
              axisTick: {
                show: false,
              },
            },
          ],
        };
        this.stackBarSeries = [
          {
            name: "原始分",
            type: "bar",
            barMaxWidth: 20,
            barGap: "80%",
            label: {
              show: true,
              color: "inherit",
              position: "top",
            },
            data: [
              ...data.map((item) => {
                return {
                  value: item.averageScoreOri,
                  name: item.eleccombName + "原始分",
                };
              }),
            ],
          },
          {
            name: "赋分",
            type: "bar",
            barMaxWidth: 20,
            barGap: "80%",

            label: {
              show: true,
              position: "top",
              color: "inherit",
            },
            data: [
              ...data.map((item) => {
                return {
                  value: item.averageScore,
                  name: item.eleccombName + "赋分",
                };
              }),
            ],
          },
        ];
      }
      this.chartKey = Math.random();
    },
    setQuartering() {
      const physics = this.tableData.filter(
        (item) => item.eleccombName && item.eleccombName.indexOf("物") != -1
      );
      const history = this.tableData.filter(
        (item) => item.eleccombName && item.eleccombName.indexOf("历") != -1
      );
      if (physics.length) {
        this.defineOptions1 = {
          dataset: [
            {
              source: [
                ...physics.map((item) => {
                  return [
                    item.lowerWhisker,
                    item.lowerQuartile,
                    item.median,
                    item.upperQuartile,
                    item.upperWhisker,
                  ];
                }),
              ],
            },
            {
              transform: {
                type: "boxplot",
                config: {},
              },
            },
            {
              fromDatasetIndex: 1,
              fromTransformResult: 1,
            },
          ],

          series: [
            {
              name: "盒须图",
              type: "boxplot",
              datasetIndex: 1,
              itemStyle: {
                color: "#7AB5EF",
              },
              boxWidth: [20, 20],
            },
            {
              name: "偏移值",
              type: "scatter",
              datasetIndex: 2,
              itemStyle: {
                color: "#4589EF",
              },
              symbolSize: 4,
            },
            {
              type: "line",
              name: "物理类最高分",
              symbol: "none",
              lineStyle: {
                type: "dashed",
              },
              color: "#FF0000",
              // color:'#31B400'
              data: [...physics.map((item) => item.eleccateHighestScore)],
            },
            {
              type: "line",
              name: "物理类最低分",
              symbol: "none",
              lineStyle: {
                type: "dashed",
              },
              data: [...physics.map((item) => item.eleccateLowestScore)],
              color: "#ED6024",
              // color:'#2D49FF'
            },
            {
              type: "line",
              name: "物理类中位数",
              symbol: "none",
              lineStyle: {
                type: "dashed",
              },
              data: [...physics.map((item) => item.eleccateMedian)],
              color: "#FFAB00",
              // color:'#00CBC7'
            },
          ],
        };
        this.xAxis1 = [...physics.map((item) => item.eleccombName)];
      }
      if (history.length) {
        this.defineOptions2 = {
          dataset: [
            {
              source: [
                ...history.map((item) => {
                  return [
                    item.lowerWhisker,
                    item.lowerQuartile,
                    item.median,
                    item.upperQuartile,
                    item.upperWhisker,
                  ];
                }),
              ],
            },
            {
              transform: {
                type: "boxplot",
                config: {},
              },
            },
            {
              fromDatasetIndex: 1,
              fromTransformResult: 1,
            },
          ],
          legend: {
            data: [
              { name: "历史类最高分" },
              { name: "历史类最低分" },
              { name: "历史类中位数" },
            ],
          },
          series: [
            {
              name: "盒须图",
              type: "boxplot",
              datasetIndex: 1,
              itemStyle: {
                color: "#7AB5EF",
              },
              boxWidth: [20, 20],
            },
            {
              name: "偏移值",
              type: "scatter",
              datasetIndex: 2,
              itemStyle: {
                color: "#4589EF",
              },
              symbolSize: 4,
            },
            {
              type: "line",
              name: "历史类最高分",
              symbol: "none",
              lineStyle: {
                type: "dashed",
              },
              color: "#31B400",
              // color:'#31B400'
              data: [...history.map((item) => item.eleccateHighestScore)],
            },
            {
              type: "line",
              name: "历史类最低分",
              symbol: "none",
              lineStyle: {
                type: "dashed",
              },
              data: [...history.map((item) => item.eleccateLowestScore)],
              color: "#00CBC7",
              // color:'#2D49FF'
            },
            {
              type: "line",
              name: "历史类中位数",
              symbol: "none",
              lineStyle: {
                type: "dashed",
              },
              data: [...history.map((item) => item.eleccateMedian)],
              color: "#2D49FF",
              // color:'#00CBC7'
            },
          ],
        };
        this.xAxis2 = [...history.map((item) => item.eleccombName)];
      }
    },
    setRatio() {
      let ratios = [];
      if (this.tableData.length) {
        ratios = this.tableData[0].ratios;
      }
      let ratioCols = ratios.map((item, index) => ({
        title: item.name,
        dataIndex: `ratio${index}`,
        align: "center",
        customRender: (text, record) => {
          let itemData = record.ratios[index];
          if (this.dataSource == 0) {
            return (
              <span>
                {itemData.totalOri}/{itemData.proportionOri}%
              </span>
            );
          } else if (this.dataSource == 1) {
            return (
              <span>
                {itemData.total}/{itemData.proportion}%
              </span>
            );
          }
        },
      }));
      this.columns = [...ratio, ...ratioCols];
      this.stackOption.xAxis = {
        type: "category",
        data: [...this.tableData.map((item) => item.eleccombName)],
      };
      this.stackOption.color = ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"];
      let chartRatios = [];
      if (this.tableData.length) {
        chartRatios = this.tableData[0].chartRatios;
      }
      let key = this.dataSource == 1 ? "proportion" : "proportionOri";
      this.stackBarSeries = [
        ...chartRatios.map((item, index) => {
          let data = this.tableData.map((item) => item.chartRatios[index][key]);
          return {
            name: item.name,
            type: "bar",
            stack: "group",
            barMaxWidth: 40,

            data: [...data],
          };
        }),
      ];
    },
    setOther() {
      let data = [
        ...this.tableData.map((item) => {
          if (this.more === "discrimination") {
            return item[
              this.more + (this.dataSource == 0 ? "Ori" : "")
            ].toFixed(2);
          } else {
            return item[this.more + (this.dataSource == 0 ? "Ori" : "")];
          }
        }),
      ];
      this.stackOption = {
        color: ["#619AF1", "#A4CDF5"],
        grid: {
          right: 0,
          left: "5%",
          containLabel: true,
        },
        yAxis: {
          type: "value",
          max: Math.max(...data),
        },
        xAxis: [
          {
            type: "category",
            data: [...this.tableData.map((item) => item.eleccombName)],
            axisPointer: {
              type: "shadow",
            },
            axisTick: {
              show: false,
            },
          },
        ],
      };
      this.stackBarSeries = [
        {
          name: this.dataSource == 0 ? "原始分" : "赋分",
          type: "bar",
          label: {
            show: true,
            color: "inherit",
            position: "top",
          },
          barMaxWidth: 40,
          data: data,
        },
      ];
      this.chartKey = Math.random();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../new-exam.module.scss";

.operate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}

.charts {
  display: flex;
  width: 100%;
  align-items: center;
}

.more-operate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}

::v-deep .rowcolor {
  color: #2474ed;
  font-weight: 600;
}
</style>
