export const avg = [
  {
    title: "组合",
    align: "center",
    dataIndex: "eleccombName",
  },
  {
    title: "统计人数",
    align: "center",
    dataIndex: "statTotal",
  },
  {
    title: "平均分",
    align: "center",
    dataIndex: "averageScoreOri",
  },
  {
    title: "中位数",
    align: "center",
    dataIndex: "medianOri",
  },
  {
    title: "赋分均分",
    align: "center",
    dataIndex: "averageScore",
  },
  {
    title: "最高分",
    align: "center",
    dataIndex: "highestScoreOri",
  },
  {
    title: "最低分",
    align: "center",
    dataIndex: "lowestScoreOri",
  },
  {
    title: "排名前27%均分",
    align: "center",
    dataIndex: "top27AverageScoreOri",
  },
  {
    title: "排名后27%均分",
    align: "center",
    dataIndex: "after27AverageScoreOri",
  },
  {
    title: "标准差",
    align: "center",
    dataIndex: "standardDeviationOri",
  },
  {
    title: "区分度",
    align: "center",
    dataIndex: "discriminationOri",
    customRender: (test, record) => record.discriminationOri.toFixed(2),
  },
];

export const ratio = [
  {
    title: "选科组合 ",
    align: "center",
    dataIndex: "eleccombName",
  },
  {
    title: "人数",
    align: "center",
    dataIndex: "statTotal",
  },
];
export const selectOptions = [
  {
    value: "median",
    label: "中位数",
  },

  {
    value: "highestScore",
    label: "最高分",
  },
  {
    value: "lowestScore",
    label: "最低分",
  },
  {
    value: "standardDeviation",
    label: "标准差",
  },
  {
    value: "discrimination",
    label: "区分度",
  },
];
