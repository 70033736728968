<template>
  <a-popover v-model="visible" placement="bottom" trigger="hover">
    <template slot="content">
      <div class="operate">
        <a-button type="link" @click="allCheck">全选</a-button>
        <a-button type="link" @click="reverse">反选</a-button>
      </div>
      <a-checkbox-group
        v-model="checkList"
        name="checkboxgroup"
        @change="onChange"
      >
        <div class="checkbox-group">
          <a-checkbox
            v-for="(item, index) in eleccombList"
            :key="index"
            :value="item.id"
          >
            {{ item.name }}
          </a-checkbox>
        </div>
      </a-checkbox-group>
      <div class="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="submit">确定</el-button>
      </div>
    </template>
    <a-select
      v-model="eleccomb"
      not-found-content="暂无数据"
      style="width: 150px"
      :placeholder="placeholder"
      :open="false"
      @focus="showPopver"
    >
    </a-select>
  </a-popover>
</template>

<script>
export default {
  name: "ClassNums",
  props: {
    placeholder: {
      type: String,
      default: "选择选考组合",
    },
    eleccombList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      checkList: [],
      eleccomb: undefined,
      visible: false,
      old: [],
    };
  },
  watch: {
    eleccombList: {
      deep: true,
      handler(val) {
        this.checkList = [...val.map((item) => item.id)];
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.checkList = [...this.eleccombList.map((item) => item.id)];
    });
  },
  methods: {
    showPopver() {
      this.visible = true;
      this.old = [...this.checkList];
    },
    cancel() {
      this.visible = false;
      this.checkList = [...this.old];
    },
    submit() {
      if (this.checkList.length) {
        this.visible = false;
        this.$emit("change", this.checkList);
      } else {
        this.$message({
          message: "请至少选择一个选考组合",
          type: "warning",
          showClose: true,
        });
      }
    },
    allCheck() {
      this.checkList = this.eleccombList.map((item) => item.id);
    },
    reverse() {
      let data = this.eleccombList.filter(
        (item) => !this.checkList.includes(item.id)
      );
      this.checkList = [...data.map((item) => item.id)];
    },
    onChange() {
      // this.$emit("change", this.checkList);
    },
  },
};
</script>
<style lang="scss" scoped>
.operate {
  margin-bottom: 18px;
}
.checkbox-group {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
.ant-checkbox-wrapper {
  margin-bottom: 8px;
  margin-right: 8px;
}
</style>
