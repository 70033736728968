var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stat-contain"},[_c('div',{staticClass:"topData"},[_c('div',{staticClass:"school-tag"},[_vm._v(_vm._s(_vm.schoolName))]),_c('div',{staticClass:"title"},[_c('div',{staticClass:"new-exam-tag"},[_vm._v("新高考")]),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.topData.name))]),_c('div',{class:['sub-tag', 'general']},[_vm._v(" "+_vm._s(_vm.getExamType(_vm.topData.type))+" ")])]),_c('div',{staticClass:"sub"},[_c('div',{staticClass:"back",on:{"click":_vm.back}},[_c('a-icon',{attrs:{"type":"left"}}),_vm._v("返回")],1),_c('div',{staticClass:"date"},[_c('span',[_vm._v("年级："+_vm._s(_vm.topData.year)+"级")]),_c('span',[_vm._v("考试时间："+_vm._s(_vm.topData.examDate))])]),_c('div')]),_c('exportBtn',{attrs:{"request-func":_vm.requestFunc,"params":{ id: _vm.$route.query.id, type: 0 },"btn-type":"primary","text":"导出全部报表"}},[_c('div',{staticClass:"model-title"},[_vm._v("校级报告")])]),_c('div',{staticClass:"dashboard"},[_c('div',{class:[
          'box',
          'normal',
          _vm.topData.hasPhysics != 1 || _vm.topData.hasHistory != 1
            ? 'flex-box'
            : '',
        ]},[_c('div',{staticClass:"item border-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.topData.eleccombCount)),_c('span',[_vm._v("个")])]),_c('div',[_vm._v("选考组合")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.topData.classCount)),_c('span',[_vm._v("个")])]),_c('div',[_vm._v("行政班")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.topData.studentCount)),_c('span',[_vm._v("名")])]),_c('div',[_vm._v("考生")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-title"},[_vm._v(_vm._s(_vm.topData.statTotal)),_c('span',[_vm._v("名")])]),_c('div',[_vm._v("统计人数")])])]),(_vm.topData.hasPhysics == 1 || _vm.topData.hasHistory == 1)?_c('div',{staticClass:"vertical-text"},[_vm._v(" 最 高 分 ")]):_vm._e(),(_vm.topData.hasPhysics == 1 || _vm.topData.hasHistory == 1)?_c('div',{class:[
          'box',
          'other',
          _vm.topData.hasPhysics != 1 || _vm.topData.hasHistory != 1
            ? 'flex-box'
            : '',
        ]},[(_vm.topData.hasPhysics == 1)?[_c('div',{class:[
              'item',
              _vm.topData.historyMaxScoreOri && _vm.topData.historyMaxScore
                ? 'border-item'
                : '',
            ]},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"sub-item sub-border-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.topData.physicsMaxScoreOri || "-")+" ")]),_c('div',[_vm._v("原始分")])]),_c('div',{staticClass:"sub-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.topData.physicsMaxScore || "-")+" ")]),_c('div',[_vm._v("赋分")])])]),_c('div',[_vm._v("物理类")])])]:_vm._e(),(_vm.topData.hasHistory == 1)?[_c('div',{staticClass:"item"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"sub-item sub-border-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.topData.historyMaxScoreOri || "-")+" ")]),_c('div',[_vm._v("原始分")])]),_c('div',{staticClass:"sub-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.topData.historyMaxScore || "-")+" ")]),_c('div',[_vm._v("赋分")])])]),_c('div',[_vm._v("历史类")])])]:_vm._e()],2):_vm._e(),_c('div',{staticClass:"box success"},[(_vm.topData.hasPhysics == 1)?_c('div',{class:[
            'item',
            _vm.topData.hasPhysics == 1 && _vm.topData.hasHistory == 1
              ? 'border-item'
              : '',
          ]},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.topData.physicsNo1Class)+" ")]),_c('div',[_vm._v("物理类No.1")])]):_vm._e(),(_vm.topData.hasHistory == 1)?_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.topData.historyNo1Class)+" ")]),_c('div',[_vm._v("历史类No.1")])]):_vm._e()])])],1),(_vm.topData.name)?[(_vm.$route.query.schoolId)?_c('unionOverview',{attrs:{"id":"overview"}}):_c('overview',{attrs:{"id":"overview"}}),(_vm.$route.query.schoolId)?_c('unionCompetitiveness'):_c('competitiveness'),_c('branch'),(_vm.topData.statClass == 1)?[(_vm.$route.query.schoolId)?_c('unionClassCompetitive',{attrs:{"id":"competitiveness"}}):_c('class-competitive',{attrs:{"id":"competitiveness"}}),_c('classBranch')]:_vm._e(),(_vm.showStatClass())?_c('balance',{attrs:{"id":"balance"}}):_vm._e(),_c('student',{attrs:{"id":"student"}}),_c('development',{attrs:{"id":"development"}})]:_vm._e(),_c('pageNav',{attrs:{"menu-list":_vm.menuList}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }