<template>
  <div ref="branch" v-lazyChart="{ fn: getSubjectGroup }" class="container">
    <div class="sub-title">成绩分布</div>
    <div class="operate">
      <a-select
        v-model="category"
        not-found-content="暂无数据"
        style="width: 120px"
        @change="handleChange"
      >
        <a-select-option v-if="$parent.topData.hasPhysics == 1" :value="8">
          物理类
        </a-select-option>
        <a-select-option v-if="$parent.topData.hasHistory == 1" :value="4">
          历史类
        </a-select-option>
      </a-select>
      <div class="resEleccombList">
        <div
          v-for="(item, index) in resEleccombList"
          :key="'resEleccombList' + index"
          :class="[
            'resEleccombList-item',
            search.eleccombId === item.id ? 'resEleccombList-active' : '',
          ]"
          @click="handleClick(item, index)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="operate">
      数据源：
      <a-radio-group v-model="search.dataSource" @change="onChange">
        <a-radio :value="1"> 原始分</a-radio>
        <a-radio :value="2"> 赋分 </a-radio>
      </a-radio-group>
      <div class="scoreSegmentation">
        分数段区间：
        <span>上限：</span>
        <a-input v-model="search.high" style="width: 64px" />
        <span>分</span>
        <span>下限：</span>
        <a-input v-model="search.low" style="width: 64px" />
        <span>分</span>
        <span>间隔：</span>
        <a-input v-model="search.interval" style="width: 64px" />
        <span>分</span>
        <a-button type="primary" @click="getData">确定</a-button>
      </div>
    </div>
    <div class="switch">
      <a-radio-group
        v-model="type"
        :default-value="1"
        button-style="solid"
        @change="typeChange"
      >
        <a-radio-button :value="1"> 人数 </a-radio-button>
        <a-radio-button :value="2"> 占比 </a-radio-button>
      </a-radio-group>
    </div>
    <div class="charts">
      <chart
        v-if="sourceData.length && defineOptions"
        :key="tokenKey"
        :define-options="defineOptions"
        :series="chartSeries"
        :data-zoom="10"
      />
      <no-data v-else></no-data>
    </div>
    <div class="tip">
      <div class="intent">注：</div>
      <div>红色柱表示校平均分所在分数段</div>
    </div>
  </div>
</template>

<script>
import chart from "../../../components/charts.vue";
import { scoredistribution } from "@/core/api/newExamAcademic/school";
import { unionScoredistribution } from "@/core/api/newExamAcademic/union/school";
import { getstateleccomboptionV2 } from "@/core/api/academic/common";
export default {
  components: {
    chart,
  },
  data() {
    return {
      category: null,
      type: 1,
      search: {
        eleccombId: null,
        type: 2,
        dataSource: 1,
        high: null,
        low: null,
        interval: null,
      },
      resEleccombList: [],
      chartSeries: [],
      tokenKey: Math.random(),
      sourceData: [],
      defineOptions: {},
    };
  },
  mounted() {
    if (this.$parent.topData.hasPhysics == 1) {
      this.category = 8;
    } else if (this.$parent.topData.hasHistory == 1) {
      this.category = 4;
    }
  },
  methods: {
    async getSubjectGroup(loadingInstance, io) {
      let obj = {
        4: 99,
        8: 98,
      };
      if (this.category !== null) {
        const res = await getstateleccomboptionV2({
          eleccateId: obj[this.category],
          hasCate: 1,
          id: this.$route.query.id,
          viewSchoolId: this.$route.query.schoolId,
        }).catch(() => this.closeLoading(loadingInstance, io));
        this.resEleccombList = res.data.data;
        if (this.resEleccombList.length) {
          this.search.eleccombId = res.data.data[0].id;
          this.typeChange();
        }
      }
      this.closeLoading(loadingInstance, io);
    },
    isNumber() {
      return (
        /^[0-9]*$/.test(this.search.high) &&
        /^[0-9]*$/.test(this.search.low) &&
        /^[0-9]*$/.test(this.search.interval)
      );
    },
    async getData() {
      const result = this.isNumber();
      if (!result) {
        this.$message({
          type: "warning",
          message: "请输入正整数",
          showClose: true,
        });
        return false;
      }
      let res;
      if (this.$route.query.schoolId) {
        res = await unionScoredistribution({
          id: this.$route.query.id,
          high: this.search.high,
          low: this.search.low,
          interval: this.search.interval,
          dataSource: this.search.dataSource,
          eleccombId: this.search.eleccombId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await scoredistribution({
          id: this.$route.query.id,
          high: this.search.high,
          low: this.search.low,
          interval: this.search.interval,
          dataSource: this.search.dataSource,
          eleccombId: this.search.eleccombId,
        });
      }

      this.sourceData = res.data.data;
      this.defineOptions = {
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          data: [...this.sourceData.map((item) => item.segment)],
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
          extraCssText:
            "max-width:200px;white-space: pre-wrap;word-break: break-all;word-wrap: break-word;",
          formatter: (data) => {
            if (this.type == 1 || this.$route.query.examType != 1) {
              return (
                data[0].name +
                "\n" +
                data[0].marker +
                data[0].seriesName +
                ":" +
                data[0].value +
                "人"
              );
            }
            if (
              this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1
            ) {
              return (
                data[0].name +
                "\n" +
                data[0].marker +
                data[0].seriesName +
                "：" +
                (data[0].value + "%") +
                "%" +
                "\n" +
                data[1].marker +
                data[1].seriesName +
                "：" +
                (data[1].value + "%")
              );
            } else {
              return (
                data[0].name +
                "\n" +
                data[0].marker +
                data[0].seriesName +
                "：" +
                (data[0].value + "%")
              );
            }
          },
        },
      };
      this.chartSeries = [
        {
          data: [
            ...this.sourceData.map((item) => {
              if (this.type === 1) {
                if (item.isGradeAverage === 1) {
                  return {
                    value: item.total,
                    itemStyle: {
                      color: "#F56C6B",
                    },
                  };
                } else {
                  return item.total;
                }
              } else {
                return item.proportion;
              }
            }),
          ],
          type: "bar",
          barMaxWidth: 40,
          name: "学校",
          label: {
            position: "top",
            color: "inherit",
            show: true,
            formatter: ({ value }) => (this.type === 1 ? value : value + "%"),
          },
        },
      ];
      if (
        this.$route.query.examType == 1 &&
        this.type == 2 &&
        this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1
      ) {
        this.chartSeries.push({
          name: "整体",
          type: "line",
          color: "#FF8857",
          data: this.sourceData.map((item) => item.overallProportion),
        });
      }
      this.tokenKey = Math.random();
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.branch);
        });
      }
    },
    handleChange() {
      this.getSubjectGroup();
    },
    handleClick(item) {
      this.search.eleccombId = item.id;
      this.defineOptions = null;
      this.getData();
    },
    checkAll() {
      this.search.eleccombId = null;
    },
    onChange() {
      this.typeChange();
    },
    typeChange() {
      const { segmentMaximum, segmentMinimum, segmentGap } =
        this.$parent.topData;
      this.search.high = segmentMaximum;
      this.search.low = segmentMinimum;
      this.search.interval = segmentGap;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../new-exam.module.scss";
.operate {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 18px;
  .scoreSegmentation {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 40px;
    span {
      margin: 0 8px;
    }
  }
}
.switch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 18px;
}
</style>
