<template>
  <div class="container">
    <div class="sub-title">班级成绩分布</div>
    <div class="operate">
      数据源：<a-radio-group
        v-model="search.dataSource"
        :options="plainOptions"
        @change="getData"
      />
      <div class="range">
        <template v-if="search.type === 0">
          分数段区间：
          <span>上限：</span>
          <a-input v-model="search.high" style="width: 64px" />
          <span style="margin-right: 18px">分</span>
          <span>下限：</span>
          <a-input v-model="search.low" style="width: 64px" />
          <span style="margin-right: 18px">分</span>
          <span>间隔：</span>
          <a-input v-model="search.interval" style="width: 64px" />
          <span style="margin-right: 18px">分</span>
        </template>
        <template v-if="search.type === 1">
          排名段区间：

          <a-input v-model="search.high" style="width: 64px" />
          <span style="margin-right: 18px">名 -</span>

          <a-input v-model="search.low" style="width: 64px" />
          <span style="margin-right: 18px">名</span>
          <span>间隔：</span>
          <a-input v-model="search.interval" style="width: 64px" />
          <span style="margin-right: 18px">名</span>
        </template>
        <a-button type="primary" @click="getData">确定</a-button>
      </div>
    </div>
    <!-- <div class="switch">
      <a-radio-group
        v-model="search.type"
        button-style="solid"
        @change="typeChange"
      >
        <a-radio-button :value="0"> 分数段 </a-radio-button>
        <a-radio-button :value="1"> 排 名 </a-radio-button>
      </a-radio-group>
    </div> -->
    <heat-vue
      v-if="chartData.length"
      :key="chartKey"
      :source="heatData"
      :tooltip="tooltip"
      :item-height="50"
    />
    <no-data v-else />
    <div class="tip" style="margin-top: 18px">
      <div class="intent">注：</div>
      <div class="text">
        <div>
          横坐标为班级，按照全部班级排序，纵坐标为分数（或排名）区间，色块数值为该班级该分数（或排名）区间内的人数，人数越多则颜色越深，如出现空白区域，则该班级该分数（或排名）区域内没有人数，如分数（或排名）中段区域连续出现多个空白，则代表该班级该分数（或排名）范围内学生断档。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import heatVue from "@/views/academic/charts/heat.vue";
import { classscoredistribution } from "@/core/api/newExamAcademic/school";
import { unionClassscoredistribution } from "@/core/api/newExamAcademic/union/school";
import bus from "../../eventBus";
export default {
  name: "ClassBranch",
  components: {
    heatVue,
  },
  data() {
    return {
      plainOptions: [
        {
          label: "原始分",
          value: 1,
        },
        {
          label: "赋分",
          value: 2,
        },
      ],
      search: {
        type: 0,
        dataSource: 1,
        high: null,
        low: null,
        interval: null,
      },
      heatData: {
        xAxis: [],
        yAxis: [],
        data: [],
      },
      tooltip: {
        trigger: "item",
        backgroundColor: "rgba(0,0,0,0.7)",
        borderColor: "rgba(0,0,0,0.7)",
        textStyle: {
          color: "#fff",
        },
        axisPointer: {
          type: "shadow",
          shadowStyle: {
            color: "rgba(0, 0, 0, 0.1)",
          },
        },
        // formatter: function (val) {
        //   if (that.heatData.xAxis[val.value[0]].indexOf("班") != -1) {
        //     return `${that.heatData.xAxis[val.value[0]]}：${val.value[2]}人`;
        //   }
        //   return `${that.heatData.xAxis[val.value[0]]}班：${val.value[2]}人`;
        // },
      },
      classSegmentVos: null,
      subjectId: null,
      chartData: [],
      chartKey: Math.random(),
      subject: null,
      eleccombs: "",
    };
  },
  created() {
    this.getSubject();
  },
  methods: {
    getSubject() {
      bus.$on("subjectChange", ({ subject, eleccombs }) => {
        this.subjectId = subject.id;
        this.eleccombs = eleccombs;
        if (this.subjectId === null) {
          this.typeChange();
        } else {
          this.subject = { ...subject };
          this.typeChange();
        }
      });
    },
    async getData() {
      let res;
      if (this.$route.query.schoolId) {
        res = await unionClassscoredistribution({
          id: this.$route.query.id,
          ...this.search,
          subjectId: this.subjectId,
          eleccombs: this.eleccombs,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await classscoredistribution({
          id: this.$route.query.id,
          ...this.search,
          subjectId: this.subjectId,
        });
      }

      this.chartData = res.data.data;
      if (res.data.data.length) {
        let classVo = res.data.data[0].classSegmentVOs;
        this.classSegmentVos = classVo;
        this.heatData.xAxis = res.data.data.map((item) => item.name);
        this.heatData.yAxis = this.classSegmentVos.map((item) => item.name);
        let data = this.chartData.flatMap((item, index) => {
          let itemClass = item.classSegmentVOs;
          return itemClass.map((it, ind) => [
            index,
            ind,
            it.total === 0 ? null : it.total,
          ]);
        });
        let sourceData = this.chartData.flatMap((item) =>
          item.classSegmentVOs.map((it) => it.total)
        );
        this.heatData.data = [...data];
        this.heatData.max = Math.max(...sourceData);
        this.heatData.min = Math.min(...sourceData);
        this.chartKey = Math.random();
      }
    },
    typeChange() {
      if (this.search.type === 0) {
        if (this.subjectId === 0) {
          let { segmentMaximum, segmentMinimum, segmentGap } =
            this.$parent.topData;
          this.search.high = segmentMaximum;
          this.search.low = segmentMinimum;
          this.search.interval = segmentGap;
        } else {
          this.search.high = this.subject.segmentMaximum;
          this.search.low = this.subject.segmentMinimum;
          this.search.interval = this.subject.segmentGap;
        }

        this.$forceUpdate();
        this.getData();
      } else if (this.search.type === 1) {
        let { rankMinimum, rankMaximum, rankGap } = this.$parent.topData;
        this.search.high = rankMaximum;
        this.search.low = rankMinimum;
        this.search.interval = rankGap;

        this.$forceUpdate();
        this.getData();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../new-exam.module.scss";
.operate {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 18px;
  .range {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 40px;
    span {
      margin: 0 8px;
    }
  }
}
.switch {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 18px;
}
</style>
