import request from "@/core/services/axios";
export const getschoolreporttopinfo = (params) => {
  // 获取校级报告顶部数据-联考新高考
  return request({
    url: `/report/statunionnewgaokaoschoolreport/getschoolreporttopinfo`,
    method: "GET",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};

export const eleccombcompetitive = (params) => {
  // 校级报告-考情概述-组合竞争力-联考新高考
  return request({
    url: `/report/statunionnewgaokaoschoolreport/getschoolreportsummary/eleccombcompetitive`,
    method: "GET",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};

export const subjectcompetitive = (params) => {
  // 校级报告-考情概述-学科竞争力-联考新高考
  return request({
    url: `/report/statunionnewgaokaoschoolreport/getschoolreportsummary/subjectcompetitive`,
    method: "GET",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};

export const unionScoredistribution = (params) => {
  // 级报告-考情概述-成绩分布-联考新高考
  return request({
    url: `/report/statunionnewgaokaoschoolreport/getschoolreportsummary/scoredistribution`,
    method: "POST",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};

export const getschoolreportclasscompetitive = (params) => {
  // 校级报告-班级竞争力-联考新高考
  return request({
    url: `/report/statunionnewgaokaoschoolreport/getschoolreportclasscompetitive`,
    method: "GET",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const unionClassscoredistribution = (params) => {
  // 校级报告-班级竞争力-班级成绩分布-联考新高考
  return request({
    url: `/report/statunionnewgaokaoschoolreport/getschoolreportclasscompetitive/classscoredistribution`,
    method: "POST",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const UnionGetschoolreportclasssubjectbalancecompare = (params) => {
  // 校级报告-班级学科均衡性对比-联考新高考
  return request({
    url: `report/statunionnewgaokaoschoolreport/getschoolreportclasssubjectbalancecompare`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const unionWaveStudent = (params) => {
  // 校级报告-班级学科均衡性对比-联考新高考
  return request({
    url: `/report/statunionnewgaokaoschoolreport/getschoolreportimportstudent/wave`,
    method: "post",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};

export const unionTopOrBackward = (params) => {
  // 报告-重点关注学生-拔尖生-后进生-联考新高考
  return request({
    url: `/report/statunionnewgaokaoschoolreport/getschoolreportimportstudent/toporbackward?id=${params.id}&topOrBackward=${params.topOrBackward}&type=${params.type}`,
    method: "post",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const unionTrackStudent = (params) => {
  // 校级报告-重点关注学生-跟踪生-联考新高考
  return request({
    url: `/report/statunionnewgaokaoschoolreport/getschoolreportimportstudent/tracking`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const unionGetschoolreportdevelopanalysis = (params) => {
  // 校级报告-发展性分析-联考新高考
  return request({
    url: `/report/statunionnewgaokaoschoolreport/getschoolreportdevelopanalysis`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
