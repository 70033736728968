<template>
  <div ref="competitiveness" v-lazyChart="{ fn: getEleList }" class="container">
    <div class="sub-title">组合竞争力</div>
    <div class="operate">
      <a-select v-model="category" style="width: 220px" @change="handleChange">
        <a-select-option
          v-for="(item, index) in cateOptions"
          :key="index"
          :value="item.eleccateSubjectId"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>

      <a-radio-group v-model="type" button-style="solid" @change="typeChange">
        <a-radio-button :value="1"> 均分分析 </a-radio-button>
        <a-radio-button :value="2"> 比率 </a-radio-button>
        <a-radio-button :value="3"> 其他指标 </a-radio-button>
      </a-radio-group>
    </div>
    <div style="margin-bottom: 18px">
      数据源：<a-radio-group
        v-model="dataSource"
        :options="plainOptions"
        @change="dataSourceChange"
      />
    </div>
    <a-table
      bordered
      :data-source="tableData"
      :columns="columns"
      :row-key="(record, index) => index"
      :scroll="{ x: 'max-content' }"
      :pagination="false"
      style="margin-bottom: 18px"
    >
      <template slot="averageScoreOri">
        <div>平均分</div>
        <div>（原始分）</div>
      </template>
      <template slot="averageScore">
        <div>平均分</div>
        <div>（赋分）</div>
      </template>
      <template slot="highestScoreOri">
        <div>最高分</div>
        <div v-if="dataSource == 0">（原始分）</div>
        <div v-else>（赋分）</div>
      </template>
      <template slot="lowestScoreOri">
        <div>最低分</div>
        <div v-if="dataSource == 0">（原始分）</div>
        <div v-else>（赋分）</div>
      </template>
      <template slot="medianOri">
        <div>中位数</div>
        <div v-if="dataSource == 0">（原始分）</div>
        <div v-else>（赋分）</div>
      </template>
      <template slot="standardDeviationOri">
        <div>标准差</div>
        <div v-if="dataSource == 0">（原始分）</div>
        <div v-else>（赋分）</div>
      </template>
      <template slot="top27AverageScoreOri">
        <div>前27%均分</div>
        <div v-if="dataSource == 0">（原始分）</div>
        <div v-else>（赋分）</div>
      </template>
      <template slot="after27AverageScoreOri">
        <div>后27%均分</div>
        <div v-if="dataSource == 0">（原始分）</div>
        <div v-else>（赋分）</div>
      </template>
    </a-table>
    <div class="operate">
      <span></span>
      <a-select
        v-if="type !== 1"
        v-model="selectValue"
        style="width: 220px"
        @change="selectChange"
      >
        <a-select-option
          v-for="(item, index) in selectOptions"
          :key="index"
          :value="item[selectKey.value]"
        >
          {{ item[selectKey.label] }}
        </a-select-option>
      </a-select>
    </div>
    <chart :key="chartKey" :define-options="defineOptions" :series="series" />
  </div>
</template>
<script>
import {
  getstateleccateoption,
  getstatratiooption,
} from "@/core/api/academic/common";
import chart from "../../../components/charts";
import { eleccombcompetitive } from "@/core/api/newExamAcademic/union/school";
export default {
  name: "Test",
  components: {
    chart,
  },
  data() {
    return {
      selectValue: null,
      selectOptions: [],
      selectKey: {
        label: null,
        value: null,
      },
      plainOptions: [
        { label: "原始分", value: 0 },
        { label: "赋分", value: 1 },
      ],
      cateOptions: [],
      chartKey: Math.random(),
      defineOptions: null,
      series: [],
      category: null,
      type: 1,
      tableData: [],
      dataSource: 0,
      columns: [],
      commonCols: [
        {
          title: "选科组合",
          align: "center",
          dataIndex: "eleccombName",
          customRender: (text, record, index) => {
            if (
              this.$parent.topData.isSchoolDisplayReportUnionAverageScore != 1
            ) {
              return text;
            }
            let obj = {
              children: text,
              attrs: {},
            };
            if (index % 2 == 0) {
              obj.attrs.rowSpan = 2;
            } else {
              obj.attrs.rowSpan = 0;
            }
            return obj;
          },
        },
        {
          title: "学校",
          align: "center",
          dataIndex: "schoolName",
        },
        {
          title: "统计人数",
          align: "center",
          dataIndex: "statTotal",
        },
      ],
      avgCols: [
        {
          align: "center",
          slots: { title: "averageScoreOri" },
          dataIndex: "averageScoreOri",
        },
        {
          align: "center",
          slots: { title: "averageScore" },
          dataIndex: "averageScore",
        },
        {
          align: "center",
          slots: { title: "highestScoreOri" },
          dataIndex: "highestScoreOri",
          customRender: (text, record) =>
            record[this.dataSource == 0 ? "highestScoreOri" : "highestScore"],
        },
        {
          align: "center",
          slots: { title: "lowestScoreOri" },
          dataIndex: "lowestScoreOri",
          customRender: (text, record) =>
            record[this.dataSource == 0 ? "lowestScoreOri" : "lowestScore"],
        },
        {
          align: "center",
          slots: { title: "medianOri" },
          dataIndex: "medianOri",
          customRender: (text, record) =>
            record[this.dataSource == 0 ? "medianOri" : "median"],
        },
        {
          align: "center",
          slots: { title: "standardDeviationOri" },
          dataIndex: "standardDeviationOri",
          customRender: (text, record) =>
            record[
              this.dataSource == 0
                ? "standardDeviationOri"
                : "standardDeviation"
            ],
        },
        {
          align: "center",
          slots: { title: "top27AverageScoreOri" },
          dataIndex: "top27AverageScoreOri",
          customRender: (text, record) => {
            if (record.schoolName == "整体") {
              return "-";
            }
            return record[
              this.dataSource == 0
                ? "top27AverageScoreOri"
                : "top27AverageScore"
            ];
          },
        },
        {
          align: "center",
          slots: { title: "after27AverageScoreOri" },
          dataIndex: "after27AverageScoreOri",
          customRender: (text, record) => {
            if (record.schoolName == "整体") {
              return "-";
            }
            return record[
              this.dataSource == 0
                ? "after27AverageScoreOri"
                : "after27AverageScore"
            ];
          },
        },
        {
          title: "区分度",
          align: "center",
          dataIndex: "discriminationOri",
          customRender: (text, record) => {
            if (record.schoolName == "整体") {
              return "-";
            }
            return record[
              this.dataSource == 0 ? "discriminationOri" : "discrimination"
            ];
          },
        },
      ],
      ratiosOptions: [],
      otherOptions: [
        {
          value: "median",
          label: "中位数",
        },

        {
          value: "highestScore",
          label: "最高分",
        },
        {
          value: "lowestScore",
          label: "最低分",
        },
        {
          value: "standardDeviation",
          label: "标准差",
        },
        {
          value: "variance",
          label: "方差",
        },
        {
          value: "coefficientOfVariation",
          label: "差异系数",
        },
      ],
    };
  },
  mounted() {
    this.getRatioList();
  },
  methods: {
    async getRatioList() {
      const res = await getstatratiooption({ id: this.$route.query.id });
      this.ratiosOptions = res.data.data;
    },
    dataSourceChange() {
      if (this.type == 1) {
        this.setAvg();
      } else if (this.type == 2) {
        this.setRatio();
      } else {
        this.setOther();
      }
    },
    async getData(loadingInstance, io) {
      const res = await eleccombcompetitive({
        id: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
        eleccateSubjectId: this.category,
      });
      this.closeLoading(loadingInstance, io);
      const data = res.data.data.flatMap((item) =>
        item.schools.map((it) => ({
          ...it,
          eleccombName: item.eleccombName,
          eleccateSubjectId: item.eleccateSubjectId,
          type: item.type,
        }))
      );
      if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore != 1) {
        this.tableData = data.filter((item) => item.schoolName !== "整体");
      } else {
        this.tableData = [...data];
      }
      this.typeChange();
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.competitiveness);
        });
      }
    },
    async getEleList(loadingInstance, io) {
      const res = await getstateleccateoption({
        id: this.$route.query.id,
      });

      this.cateOptions = res.data.data;
      if (res.data.data.length) {
        this.category = res.data.data[0].eleccateSubjectId;
        this.getData(loadingInstance, io);
      }
    },
    selectChange() {
      if (this.type == 2) {
        this.setRatiotData("proportion");
      } else if (this.type == 3) {
        this.setOtherData();
      }
    },
    typeChange() {
      const { totalData } = this.createData();
      this.defineOptions = {
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          data: totalData.map((item) => item.eleccombName),
          color: ["#2474ED", "#FF8857"],
        },
      };
      const funcEnmu = {
        1: this.setAvg,
        2: this.setRatio,
        3: this.setOther,
      };
      if (this.type == 2) {
        this.selectOptions = this.ratiosOptions;
        if (this.ratiosOptions.length) {
          this.selectValue = this.ratiosOptions[0].name;
        }
        this.selectKey = {
          label: "name",
          value: "name",
        };
      } else if (this.type == 3) {
        this.selectOptions = this.otherOptions;
        if (this.otherOptions.length) {
          this.selectValue = this.otherOptions[0].value;
        }
        this.selectKey = {
          label: "label",
          value: "value",
        };
      }

      funcEnmu[this.type]();
    },
    createData() {
      let totalData = this.tableData.filter(
        (item) => item.schoolName == "整体"
      );
      let currentData = this.tableData.filter(
        (item) => item.schoolName == "本校"
      );
      if (this.dataSource == 1) {
        totalData = totalData.filter(
          (item) => ![1, 2, 3, 4, 8].includes(item.subjectId)
        );
        currentData = currentData.filter(
          (item) => ![1, 2, 3, 4, 8].includes(item.subjectId)
        );
      }
      return { totalData: totalData, currentData: currentData };
    },
    setAvg() {
      this.columns = [...this.commonCols, ...this.avgCols];
      const { totalData, currentData } = this.createData();
      this.defineOptions = {
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          data: totalData.map((item) => item.eleccombName),
          color: ["#2474ED", "#FF8857"],
        },
      };
      let key = this.dataSource == 1 ? "averageScore" : "averageScoreOri";

      this.series = [
        {
          name: "学校",
          type: "bar",
          color: "#2474ED",
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
          barMaxWidth: 40,
          data: [
            ...currentData.map((item) => {
              return key in item ? item[key] : null;
            }),
          ],
        },
      ];
      if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
        this.series.push({
          name: "整体",
          type: "line",
          color: "#FF8857",
          smooth: true,
          data: [
            ...totalData.map((item) => {
              return key in item ? item[key] : null;
            }),
          ],
        });
      }
      this.chartKey = Math.random();
    },
    setRatio() {
      this.columns = [
        ...this.commonCols,
        ...this.ratiosOptions.map((item, index) => ({
          title: item.name,
          dataIndex: "ratios" + index,
          align: "center",
          customRender: (text, record) => {
            if (this.dataSource == 0) {
              return record.ratios[index]["proportionOri"] + "%";
            }
            return record.ratios[index]["proportion"] + "%";
          },
        })),
      ];
      this.setRatiotData("proportion");
    },
    setOther() {
      this.columns = [
        ...this.commonCols,
        {
          title: "中位数",
          dataIdnex: "medianOri",
          align: "center",
          customRender: (text, record) =>
            this.dataSource == 0 ? record["medianOri"] : record["median"],
        },
        {
          title: "最高分",
          dataIdnex: "highestScoreOri",
          align: "center",
          customRender: (text, record) =>
            this.dataSource == 0
              ? record["highestScoreOri"]
              : record["highestScore"],
        },
        {
          title: "最低分",
          dataIdnex: "lowestScoreOri",
          align: "center",
          customRender: (text, record) =>
            this.dataSource == 0
              ? record["lowestScoreOri"]
              : record["lowestScore"],
        },
        {
          title: "标准差",
          dataIdnex: "standardDeviationOri",
          align: "center",
          customRender: (text, record) =>
            this.dataSource == 0
              ? record["standardDeviationOri"]
              : record["standardDeviation"],
        },
        {
          title: "方差",
          dataIdnex: "varianceOri",
          align: "center",
          customRender: (text, record) => {
            return this.dataSource == 1 ? record.variance : record.varianceOri;
          },
        },
        {
          title: "差异系数",
          align: "center",
          dataIndex: "coefficientOfVariationOri",
          customRender: (text, record) => {
            return this.dataSource == 0
              ? record["coefficientOfVariationOri"]
              : record["coefficientOfVariation"];
          },
        },
      ];
      this.setOtherData();
    },
    setRatiotData(field) {
      const { totalData, currentData } = this.createData();
      let key = this.dataSource == 1 ? field : field + "Ori";
      this.series = [
        {
          name: "学校",
          type: "bar",
          color: "#2474ED",
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
          barMaxWidth: 40,
          data: [
            ...currentData.map((item) => {
              const obj = item.ratios.find(
                (it) => it.name === this.selectValue
              );
              return obj[key];
            }),
          ],
        },
      ];
      if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
        this.series.push({
          name: "整体",
          type: "line",
          smooth: true,
          color: "#FF8857",
          data: [
            ...totalData.map((item) => {
              const obj = item.ratios.find(
                (it) => it.name === this.selectValue
              );
              return obj[key];
            }),
          ],
        });
      }
      this.chartKey = Math.random();
    },
    setOtherData() {
      const { totalData, currentData } = this.createData();
      let key =
        this.dataSource == 1 ? this.selectValue : this.selectValue + "Ori";
      this.series = [
        {
          name: "学校",
          type: "bar",
          color: "#2474ED",
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
          barMaxWidth: 40,
          data: [
            ...currentData.map((item) => {
              return item[key];
            }),
          ],
        },
      ];
      if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
        this.series.push({
          name: "整体",
          type: "line",
          color: "#FF8857",
          smooth: true,
          data: [
            ...totalData.map((item) => {
              return item[key];
            }),
          ],
        });
      }
      this.chartKey = Math.random();
    },
    handleChange() {
      this.getData();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../new-exam.module.scss";

.operate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}
</style>
