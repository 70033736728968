<template>
  <div
    ref="classCompetitiveness"
    v-lazyChart="{ fn: getCondition }"
    class="container"
  >
    <exportBtn
      :request-func="requestFunc"
      :params="{ id: $route.query.id, type: 3 }"
    >
      <div class="title">班级竞争力</div>
    </exportBtn>
    <div class="filter-box">
      <div class="subjectList">
        <div
          v-for="(item, index) in subjectList"
          :key="index"
          :class="['subject', search.subjectId === item.id ? 'active' : '']"
          @click="handleClick(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="operate">
        <a-select
          v-model="search.cate"
          not-found-content="暂无数据"
          style="width: 120px; margin-right: 8px"
          :disabled="cateDisabled"
          @change="handleChange"
        >
          <a-select-option :value="0"> 全部类别 </a-select-option>
          <a-select-option v-if="$parent.topData.hasPhysics == 1" :value="8">
            物理类
          </a-select-option>
          <a-select-option v-if="$parent.topData.hasHistory == 1" :value="4">
            历史类</a-select-option
          >
        </a-select>
        <eleccomb
          style="margin-right: 8px"
          :eleccomb-list="resEleccombList"
          @change="resEleccomChange"
        />
        <classNums
          ref="classNums"
          style="margin-right: 8px"
          :placeholder="'选择' + getClassType(search.subjectId) + '班'"
          :request-page="1"
          :class-num-list="classNumList"
          @change="classNumChange"
        />
      </div>
    </div>
    <div class="category">
      <div>
        <template
          v-if="
            (search.category == 3 || search.category == 4) &&
            ![0, 1, 2, 3, 4, 8].includes(search.subjectId)
          "
        >
          <span>数据源：</span>
          <a-radio-group
            v-model="search.dataSource"
            :options="plainOptions"
            @change="categoryChange"
          />
        </template>
      </div>
      <a-radio-group
        v-model="search.category"
        button-style="solid"
        @change="categoryChange"
      >
        <a-radio-button :value="1"> 均分分析 </a-radio-button>
        <a-radio-button :value="2"> 四分位分析 </a-radio-button>
        <a-radio-button v-if="ratios.length" :value="3"> 比 率 </a-radio-button>
        <a-radio-button :value="4"> 更多指标 </a-radio-button>
      </a-radio-group>
    </div>
    <div v-if="search.category === 4" class="dataSource">
      <div></div>
      <a-select
        v-model="search.dataType"
        not-found-content="暂无数据"
        style="width: 120px"
        @change="categoryChange"
      >
        <a-select-option value="standardDeviation"> 标准差</a-select-option>
        <a-select-option value="variance"> 方差</a-select-option>
        <a-select-option value="coefficientOfVariation">
          差异系数</a-select-option
        >
        <a-select-option value="scoreRate"> 得分率</a-select-option>
        <a-select-option value="overAverageRate"> 超均率</a-select-option>
        <a-select-option value="discrimination"> 区分度</a-select-option>
        <a-select-option value="highestScore"> 最高分</a-select-option>
        <a-select-option value="lowestScore"> 最低分</a-select-option>
      </a-select>
    </div>
    <ex-table
      :key="tableKey"
      v-loading="loading"
      :columns="columns"
      :col-span-num="colSpanNum"
      :data-source="tableData"
    >
    </ex-table>
    <template v-if="tableData.length">
      <template v-if="search.category != 2">
        <chart
          :key="chartKey"
          :define-options="defineOptions"
          :series="series"
          :data-zoom="8"
        />
      </template>
      <template v-else>
        <BoxPlot
          v-if="defineOptions"
          :key="'BoxPlot' + chartKey"
          show-tooltip
          :x-axis="BoxPlotXAxis"
          :define-options="defineOptions"
        />
        <no-data v-else />
      </template>
    </template>
    <no-data v-else />
    <div class="tip">
      <div class="intent">注：</div>
      <div v-if="search.category === 1" class="text">
        <div>除总分按照赋分排名外，所有学科按照原始分进行排名。</div>
        <div>总分按照物理类，历史类分别进行校排名。</div>
        <div>
          参考班级和组合在学科内按照原始分进行排名，不区分物理类和历史类。
        </div>
      </div>
      <div v-if="search.category === 2" class="text">
        <div>以上数据结果均以赋分进行统计运算</div>
        <div>以上数据均不包括不参与统计的考生成绩</div>
        <div>
          中位数（大类）指在本班物理类或历史类中该成绩的中位数；中位数（组合）指本班该组合成绩的中位数；最高分、最低分同理
        </div>
      </div>
      <div v-if="search.category === 3" class="text">
        <div>
          以上数据结果均以{{
            search.dataSource == 0 ? "原始分" : "赋分"
          }}进行统计运算
        </div>
        <div>以上数据均不包括不参与统计的考生成绩</div>
      </div>
      <div v-if="search.category === 4" class="text">
        <div>
          以上选考科目（生物、政治、化学、地理）数据结果采用{{
            search.dataSource == 0 ? "原始分" : "赋分"
          }}进行统计运算
        </div>
        <div>
          方差
          各个数据与平均数之差的平方的和的平均数，用来衡量一批数据的波动大小(即这批数据偏离平均数的大小)
        </div>
        <div>
          标准差 方差的算术平方根
          表示一组数值的离散程度，标准差越大，表示该组数值与均值的偏差越大
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chart from "../../../components/charts.vue";
import BoxPlot from "../../../components/BoxPlot.vue";
import exTable from "../../../components/exTable.vue";
import classNums from "../../../components/classNums.vue";
import eleccomb from "../../../components/eleccomb.vue";
import {
  getstatsubjectoption,
  getstateleccomboptionV2,
  getstatclassoption,
} from "@/core/api/academic/common";
import { getschoolreportclasscompetitive } from "@/core/api/newExamAcademic/school";
import { exportSchoolNewReport } from "@/core/api/academic/schoolReportDetail";
import { getClassType } from "../../../func";
import exportBtn from "@/views/academic/components/exportBtn.vue";
import bus from "../../eventBus";
export default {
  name: "ClassCompetitiveness",
  components: {
    chart,
    BoxPlot,
    exTable,
    classNums,
    eleccomb,
    exportBtn,
  },
  data() {
    return {
      tableData: [],
      resEleccombIds: [],
      requestFunc: exportSchoolNewReport,
      subjectList: [],
      getClassType: getClassType,
      plainOptions: [
        { label: "原始分", value: 0 },
        { label: "赋分", value: 1 },
      ],
      cateDisabled: false,
      search: {
        subjectId: 0,
        type: 1,
        category: 1,
        dataType: "standardDeviation",
        dataSource: 0,
        cate: 0,
      },
      resEleccombList: [],
      defineOptions: {},
      series: [],
      BoxPlotXAxis: [],
      allChecked: false,
      indeterminate: false,
      columns: [],
      tableKey: Math.random(),
      avg: [
        {
          dataIndex: "rank",
          title: "大类排名",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "classNum",
          title: "班级",
          align: "center",
          width: 65,
        },
        {
          dataIndex: "eleccombName",
          title: "选考组合",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "teacherName",
          title: "任课教师",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "statTotal",
          title: "统计人数",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "averageScoreOri",
          title: "平均分",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "medianOri",
          title: "中位数",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "averageScore",
          title: "赋分均分",
          align: "center",
          width: 100,
          customRender: (text, record) => {
            let arr = [1, 2, 3, 4, 8];
            if (record.classNum === "年级均值") {
              arr = [1, 2, 3, 4, 8];
            }
            return arr.includes(this.search.subjectId)
              ? "-"
              : record.averageScore;
          },
        },
        {
          dataIndex: "highestScoreOri",
          title: "最高分",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "lowestScoreOri",
          width: 85,
          title: "最低分",
          align: "center",
        },
        {
          dataIndex: "overAverageRateOri",
          width: 100,
          title: "超均率",
          align: "center",
          customRender: (text, record) => `${record.overAverageRateOri || 0} %`,
        },
        // {
        //   dataIndex: "overAvgGrade",
        //   title: "超均比例（年级）",
        //   align: "center",
        //   width: 120,
        //   customRender: (text, record) =>
        //     `${record.overAverageCountRateOri || 0} %`,
        // },
        {
          dataIndex: "top27AverageScoreOri",
          title: "排名前27%均分",
          align: "center",
          width: 120,
        },
        {
          dataIndex: "after27AverageScoreOri",
          title: "排名后27%均分",
          align: "center",
          width: 120,
        },
        {
          dataIndex: "discriminationOri",
          title: "区分度",
          align: "center",
          width: 85,
          customRender: (test, record) => record.discriminationOri.toFixed(2),
        },
      ],
      quartering: [
        {
          dataIndex: "classNum",
          title: "行政班",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "teacherName",
          title: "任课教师",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "eleccombName",
          title: "选考组合",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "statTotal",
          title: "统计人数",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "medianOri",
          title: "中位数",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "eleccateMedian",
          title: "中位数（大类）",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "upperWhisker",
          title: "上限",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "highestScore",
          title: "最高分",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "eleccateHighestScore",
          title: "最高分（大类）",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "lowerWhisker",
          title: "下限",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "lowestScore",
          title: "最低分",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "eleccateLowestScore",
          title: "最低分（大类）",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "interQuartileRange",
          title: "四分位间距",
          align: "center",
          width: 120,
        },
        {
          dataIndex: "rangeScore",
          title: "全距/极差",
          align: "center",
          width: 120,
        },
      ],
      ratioCol: [
        {
          dataIndex: "classNum",
          title: "班级",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "teacherName",
          title: "班主任",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "eleccombName",
          title: "选考组合",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "statTotal",
          title: "统计人数",
          align: "center",
          width: 100,
        },
      ],
      moreCol: [
        {
          dataIndex: "classNum",
          title: "班级",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "teacherName",
          title: "班主任",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "eleccombName",
          title: "选考组合",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "statTotal",
          title: "统计人数",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "highestScoreOri",
          title: "最高分",
          align: "center",
          width: 85,
          customRender: (text, record) =>
            record["highestScore" + (this.search.dataSource == 0 ? "Ori" : "")],
        },
        {
          dataIndex: "lowestScoreOri",
          title: "最低分",
          align: "center",
          width: 85,
          customRender: (text, record) =>
            record["lowestScore" + (this.search.dataSource == 0 ? "Ori" : "")],
        },
        {
          dataIndex: "standardDeviationOri",
          title: "标准差",
          align: "center",
          width: 85,
          customRender: (text, record) =>
            record[
              "standardDeviation" + (this.search.dataSource == 0 ? "Ori" : "")
            ],
        },
        {
          dataIndex: "varianceOri",
          title: "方差",
          align: "center",
          width: 75,
          customRender: (text, record) =>
            record["variance" + (this.search.dataSource == 0 ? "Ori" : "")],
        },
        {
          dataIndex: "coefficientOfVariationOri",
          title: "差异系数",
          align: "center",
          width: 100,
          customRender: (text, record) =>
            record[
              "coefficientOfVariation" +
                (this.search.dataSource == 0 ? "Ori" : "")
            ],
        },
        {
          dataIndex: "discriminationOri",
          title: "区分度",
          align: "center",
          width: 100,
          customRender: (text, record) =>
            record[
              "discrimination" +
                ([0, 1, 2, 3, 4, 8].includes(this.search.subjectId) ||
                this.search.dataSource === 0
                  ? "Ori"
                  : "")
            ].toFixed(2),
        },
        {
          dataIndex: "scoreRateOri",
          title: "得分率",
          align: "center",
          width: 100,
          customRender: (text, record) =>
            record["scoreRate" + (this.search.dataSource == 0 ? "Ori" : "")] +
            "%",
        },
        {
          dataIndex: "overAverageRateOri",
          title: "超均率",
          align: "center",
          width: 100,
          customRender: (text, record) =>
            record[
              "overAverageRate" + (this.search.dataSource == 0 ? "Ori" : "")
            ] + "%",
        },
      ],
      checkClassNum: [],
      classNumList: [],
      loading: false,
      colSpanNum: 1,
      chartKey: Math.random(),
      ratios: [],
    };
  },

  mounted() {
    this.categoryChange();
  },
  activated() {
    this.categoryChange();
  },
  methods: {
    async getData() {
      this.loading = true;
      const res = await getschoolreportclasscompetitive({
        id: this.$route.query.id,
        classNums: this.checkClassNum.toString(),
        eleccombs: this.resEleccombIds.length
          ? this.resEleccombIds.toString()
          : this.resEleccombList.map((item) => item.id),
        subjectId: this.search.subjectId,
      }).catch(() => {
        this.loading = false;
      });
      if (this.resEleccombIds.length) {
        this.tableData = res.data.data;
      } else {
        this.tableData = res.data.data.filter(
          (item) => item.classNum === "年级均值"
        );
      }
      if (this.tableData.length) {
        this.ratios = this.tableData[0].ratios;
      }
      this.loading = false;
      this.categoryChange();
    },
    async getClassNumList() {
      const res = await getstatclassoption({
        id: this.$route.query.id,
        requestPage: this.requestPage,
        subjectId: this.search.subjectId,
      });
      this.classNumList = res.data.data.map((item) => item.name);
      this.checkClassNum = res.data.data.map((item) => item.name);
      this.$refs.classNums.value = this.checkClassNum;
      this.getData();
    },

    async getCondition(loadingInstance, io) {
      const res = await getstatsubjectoption({
        id: this.$route.query.id,
        hasTotal: 1,
        requestPage: 1,
      });
      this.subjectList = res.data.data;
      if (this.subjectList.length) {
        this.search.subjectId = res.data.data[0].id;
        bus.$emit("subjectChange", res.data.data[0]);
        this.getEleccombList();
      }
      this.closeLoading(loadingInstance, io);
    },
    async getEleccombList() {
      let obj = {
        0: 0,
        4: 99,
        8: 98,
      };
      const res = await getstateleccomboptionV2({
        id: this.$route.query.id,
        subjectId: this.search.subjectId == "0" ? null : this.search.subjectId,
        eleccateId: obj[this.search.cate],
        hasAll: 0,
      });
      this.resEleccombList = res.data.data;
      this.allChecked = true;
      this.resEleccombIds = this.resEleccombList.map((item) => item.id);
      this.getClassNumList();
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.classCompetitiveness);
        });
      }
    },
    handleClick(item) {
      this.search.subjectId = item.id;
      bus.$emit("subjectChange", item);
      if (item.id === 8 || item.id === 4) {
        this.search.cate = item.id;
        this.cateDisabled = true;
        this.search.dataSource = 0;
      } else {
        this.cateDisabled = false;
        this.search.cate = 0;
        this.search.dataSource = 0;
      }
      this.getEleccombList();
      // this.setColumns();
      // this.tableKey = Math.random();
    },
    classNumChange(val) {
      this.checkClassNum = val;
      this.getData();
    },
    handleChange() {
      this.getEleccombList();
    },
    resEleccomChange(checkedList) {
      this.resEleccombIds = checkedList;
      this.getData();
    },

    categoryChange() {
      this.setColumns();
      if (this.search.category === 1) {
        this.setAvg();
      } else if (this.search.category === 2) {
        this.setQuartering();
      } else if (this.search.category === 3) {
        this.setRatio();
      } else if (this.search.category === 4) {
        this.setOther();
      }
    },
    setAvg() {
      let grade = this.tableData.find((item) => item.classNum == "年级均值");
      let data = this.tableData.filter((item) => item.classNum !== "年级均值");
      this.defineOptions = {
        xAxis: [
          {
            type: "category",
            data: [...data.map((item) => item.classNum)],
            axisPointer: {
              type: "shadow",
            },
            axisTick: {
              show: false,
            },
          },
        ],
        color: ["#619AF1", "#8FB8F5"],
      };
      this.series = [
        {
          name: "班级均分(原始分)",
          type: "bar",
          data: [...data.map((item) => item.averageScoreOri)],
          barMaxWidth: 30,
          label: {
            position: "top",
            color: "inherit",
            show: true,
          },
        },

        {
          name: "年级均分（原始分）",
          type: "line",
          color: "#FF9268",
          data: [...data.map(() => grade.averageScoreOri || 0)],
        },
      ];
      if (![1, 2, 3, 4, 8].includes(this.search.subjectId)) {
        this.series.splice(1, 0, {
          name: "班级均分（赋分）",
          type: "bar",
          data: [...data.map((item) => item.averageScore)],
          barMaxWidth: 30,
          label: {
            position: "top",
            color: "inherit",
            show: true,
          },
        });
        this.series.push({
          name: "年级均分（赋分）",
          type: "line",
          color: "#F65252",
          data: [...data.map(() => grade.averageScore || 0)],
        });
      }
      this.chartKey = Math.random();
    },
    setQuartering() {
      this.defineOptions = null;
      let enmu = {
        0: "年级",
        4: "历史类",
        8: "物理类",
      };
      let noGrade = this.tableData.filter(
        (item) => item.classNum !== "年级均值"
      );
      this.BoxPlotXAxis = [...noGrade.map((item) => item.classNum)];
      let subject = this.subjectList.find(
        (item) => item.id == this.search.subjectId
      );
      let data = [
        ...noGrade.map((item) => {
          return [
            item.lowerWhisker,
            item.lowerQuartile,
            item.medianOri,
            item.upperQuartile,
            item.upperWhisker,
          ];
        }),
      ];
      let arr = data.flat();
      let result = arr.filter((item) => item);
      if (result.length) {
        this.defineOptions = {
          dataZoom: [
            { type: "slider", start: 0, end: 10, show: true, height: 20 },
          ],
          legend: {
            data: [
              { name: enmu[this.search.cate] + "最高分" },
              { name: enmu[this.search.cate] + "最低分" },
              { name: enmu[this.search.cate] + "中位数" },
            ],
          },
          dataset: [
            {
              source: [...data],
            },
            {
              transform: {
                type: "boxplot",
                config: {},
              },
            },
            {
              fromDatasetIndex: 1,
              fromTransformResult: 1,
            },
          ],
          series: [
            {
              name: subject.name,
              type: "boxplot",
              datasetIndex: 1,
              itemStyle: {
                color: "#7AB5EF",
              },
              boxWidth: [10, 20],
            },
            {
              name: "",
              type: "scatter",
              datasetIndex: 2,
              itemStyle: {
                color: "#4589EF",
              },
              symbolSize: 4,
            },
            {
              type: "line",
              name: enmu[this.search.cate] + "最高分",
              symbol: "none",
              lineStyle: {
                type: "dashed",
              },
              color: "#FF0000",
              // color:'#31B400'
              data: [
                ...this.tableData.map((item) => item.eleccateHighestScore),
              ],
            },
            {
              type: "line",
              name: enmu[this.search.cate] + "最低分",
              symbol: "none",
              lineStyle: {
                type: "dashed",
              },
              data: [...this.tableData.map((item) => item.eleccateLowestScore)],
              color: "#ED6024",
              // color:'#2D49FF'
            },
            {
              type: "line",
              name: enmu[this.search.cate] + "中位数",
              symbol: "none",
              lineStyle: {
                type: "dashed",
              },
              data: [...this.tableData.map((item) => item.eleccateMedian)],
              color: "#FFAB00",
              // color:'#00CBC7'
            },
          ],
        };
      }
      this.chartKey = Math.random();
    },
    setRatio() {
      let data = this.tableData.filter((item) => item.classNum !== "年级均值");
      this.defineOptions = {
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          data: [...data.map((item) => item.classNum)],
        },
        color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
      };
      let ratios;
      if (this.tableData.length) {
        ratios = this.tableData[0].chartRatios;
      }
      this.series = [
        ...ratios.map((item, index) => {
          return {
            name: item.name,
            type: "bar",
            stack: "subject",
            barMaxWidth: 40,

            data: [
              ...data.map((it) => {
                if (this.search.dataSource === 0) {
                  return it.chartRatios[index].proportionOri;
                } else {
                  return it.chartRatios[index].proportion;
                }
              }),
            ],
          };
        }),
      ];
      this.chartKey = Math.random();
    },
    setOther() {
      let grade = this.tableData.find((item) => item.classNum == "年级均值");
      let data = this.tableData.filter((item) => item.classNum !== "年级均值");
      this.defineOptions = {
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          data: [...data.map((item) => item.classNum)],
        },
      };
      this.series = [
        {
          name: this.search.dataSource === 0 ? "班级(原始分)" : "班级(赋分)",
          type: "bar",
          color: ["#619AF1"],
          data: [
            ...data.map((item) => {
              if (this.search.dataType === "discrimination") {
                return item[this.search.dataType + "Ori"].toFixed(2);
              } else {
                return item[this.search.dataType + "Ori"];
              }
            }),
          ],
          barMaxWidth: 40,
          label: {
            position: "top",
            color: "inherit",
            show: true,
          },
        },
        {
          name: this.search.dataSource === 0 ? "年级(原始分)" : "年级(赋分)",
          type: "line",
          color: "#F65252",
          data: [
            ...data.map(() => {
              if (this.search.dataType === "discrimination") {
                return grade[this.search.dataType + "Ori"].toFixed(2);
              } else {
                return grade[this.search.dataType + "Ori"];
              }
            }),
          ],
        },
      ];
      this.chartKey = Math.random();
    },
    titleCase(str) {
      return str.slice(0, 1).toUpperCase() + str.slice(1);
    },
    setColumns() {
      this.colSpanNum = 0;
      let columns = [];
      if (this.search.category == 1) {
        this.colSpanNum = 4;
        columns = this.avg;
        if (this.search.subjectId === 0) {
          columns[0].title = "大类排名";
        } else {
          columns[0].title = "校排名";
        }
      } else if (this.search.category == 2) {
        this.colSpanNum = 3;
        columns = this.quartering;
      } else if (this.search.category == 3) {
        this.colSpanNum = 3;
        let ratios;
        if (this.tableData.length) {
          ratios = this.tableData[0].ratios;
        }
        let cols = ratios.map((item, index) => {
          return {
            dataIndex: "ratio" + index,
            title: item.name,
            align: "center",
            customRender: (text, record) => {
              if (this.search.dataSource == 1) {
                return (
                  <span>
                    {record.ratios[index].total}人/
                    {record.ratios[index].proportion}%
                  </span>
                );
              } else {
                return (
                  <span>
                    {record.ratios[index].totalOri}人/
                    {record.ratios[index].proportionOri}%
                  </span>
                );
              }
            },
          };
        });
        columns = [...this.ratioCol, ...cols];
      } else if (this.search.category === 4) {
        this.colSpanNum = 2;
        columns = this.moreCol;
      }
      let arr = columns.map((item) => {
        let data = { ...item };
        if (item.title === "任课教师" || item.title === "班主任") {
          if (this.search.subjectId) {
            data.title = "任课教师";
            data.width = 100;
          } else {
            data.title = "班主任";
            data.width = 85;
          }
        }
        if (
          item.title === "班级" ||
          item.title === "行政班" ||
          item.title === "教学班"
        ) {
          if (this.search.subjectId === 0) {
            data.title = "班级";
            data.width = 75;
          } else if ([1, 2, 3].includes(this.search.subjectId)) {
            data.title = "行政班";
            data.width = 85;
          } else {
            data.title = "教学班";
            data.width = 85;
          }
        }

        if (this.search.category === 2) {
          let arr1 = ["中位数", "中位数（学科）"];
          let arr2 = ["最高分", "最高分（学科）"];
          let arr3 = ["最低分", "最低分（学科）"];
          if (arr1.includes(item.title)) {
            data.title =
              this.search.subjectId === 0 ? "中位数" : "中位数（学科）";
            data.width = this.search.subjectId === 0 ? 85 : 100;
          } else if (arr2.includes(item.title)) {
            data.title =
              this.search.subjectId === 0 ? "最高分" : "最高分（学科）";
            data.width = this.search.subjectId === 0 ? 85 : 100;
          } else if (arr3.includes(item.title)) {
            data.title =
              this.search.subjectId === 0 ? "最低分" : "最低分（学科）";
            data.width = this.search.subjectId === 0 ? 85 : 100;
          }
        }
        return data;
      });
      this.columns = arr;
      this.tableKey = Math.random();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../new-exam.module.scss";

.container {
  .filter-box {
    box-sizing: border-box;
    padding: 16px;
    margin-bottom: 18px;
    border-radius: 8px;
    border: 1px solid #d5d6db;

    .subjectList {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      color: #0a1119;
      font-size: 14px;

      .label {
        margin-right: 8px;
        margin-bottom: 18px;
      }

      .subject {
        border-radius: 4px;
        border: 1px solid #d5d6db;
        box-sizing: border-box;
        padding: 6px 18px;
        cursor: pointer;
        background: #fff;
        margin-bottom: 18px;
        transition: all 0.2s ease-in-out;
      }

      .active {
        color: #fff;
        background: #2474ed;
        border-color: #2474ed;
      }

      .subject + .subject {
        margin-left: 8px;
      }
    }

    .resEleccomb {
      margin-left: 21px;
    }

    .operate {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      //   margin-bottom: 18px;
    }
  }

  .category {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
  }

  .dataSource {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
  }
}

.checkbox-content {
  display: flex;
  flex-wrap: wrap;

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  .ant-checkbox-wrapper {
    margin-bottom: 8px;
    margin-right: 8px;
  }
}
</style>
